@font-face {
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  

  .icon-wi-fi-stroke:before {
    content: "\e932";
  }
  .icon-lock:before {
    content: "\e930";
  }
  .icon-minus:before {
    content: "\e92f";
  }
  .icon-search-two:before {
    content: "\e92e";
  }
  .icon-pluse:before {
    content: "\e92b";
  }
  .icon-reload:before {
    content: "\e92d";
  }
  .icon-close-border:before {
    content: "\e931";
  }
  .icon-plus-border:before {
    content: "\e92c";
  }
  .icon-options:before {
    content: "\e919";
  }
  .icon-gybrid:before {
    content: "\e900";
  }
  .icon-online:before {
    content: "\e901";
  }
  .icon-arror-send:before {
    content: "\e902";
  }
  .icon-arrow-down:before {
    content: "\e903";
  }
  .icon-arrow-left:before {
    content: "\e904";
  }
  .icon-arrow-right:before {
    content: "\e905";
  }
  .icon-arrow-up:before {
    content: "\e906";
  }
  .icon-calendar:before {
    content: "\e907";
  }
  .icon-calendar2:before {
    content: "\e908";
  }
  .icon-chat:before {
    content: "\e909";
  }
  .icon-check-icon:before {
    content: "\e90a";
  }
  .icon-close:before {
    content: "\e90b";
  }
  .icon-dashboard:before {
    content: "\e90c";
  }
  .icon-database:before {
    content: "\e90d";
  }
  .icon-delete:before {
    content: "\e90e";
  }
  .icon-doc:before {
    content: "\e90f";
  }
  .icon-edit:before {
    content: "\e910";
  }
  .icon-eye:before {
    content: "\e911";
  }
  .icon-gear:before {
    content: "\e912";
  }
  .icon-grid_view:before {
    content: "\e913";
  }
  .icon-headphones:before {
    content: "\e914";
  }
  .icon-help:before {
    content: "\e915";
  }
  .icon-icon_picture:before {
    content: "\e916";
  }
  .icon-list_view:before {
    content: "\e917";
  }
  .icon-live:before {
    content: "\e918";
  }
  .icon-male:before {
    content: "\e91a";
  }
  .icon-navigator:before {
    content: "\e91b";
  }
  .icon-partners:before {
    content: "\e91c";
  }
  .icon-payment:before {
    content: "\e91d";
  }
  .icon-phone:before {
    content: "\e91e";
  }
  .icon-placeholder:before {
    content: "\e91f";
  }
  .icon-play:before {
    content: "\e920";
  }
  .icon-plus:before {
    content: "\e921";
  }
  .icon-program:before {
    content: "\e922";
  }
  .icon-search:before {
    content: "\e923";
  }
  .icon-settings:before {
    content: "\e924";
  }
  .icon-speacker:before {
    content: "\e925";
  }
  .icon-star:before {
    content: "\e926";
  }
  .icon-team-1:before {
    content: "\e927";
  }
  .icon-team:before {
    content: "\e928";
  }
  .icon-time:before {
    content: "\e929";
  }
  .icon-wi-fi:before {
    content: "\e92a";
  }
  