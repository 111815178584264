@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
body {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    font-weight: normal;
    color: #0b1218;
    zoom: 0.8; 
}

h1 {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.19;
    letter-spacing: -0.8px;
    color: #0b1218;
}

button {
    cursor: pointer;
}

.js-button {
    border-radius: 16px;
    background-color: #f1f1f1;
    padding: 5px 10px;
    margin-right: 5px;
    font-size: 16px;
    border: none;
}
.js-button:first-child {
    margin-left: 10px;
}
.js-button.small {
    padding: 1px 20px;
}
.js-button:hover {
    background-color: #e0e0e1;
}
.js-button.selected {
    background-color: #444;
    color: #f1f1f1;
}

a {
    outline: none !important;
    text-decoration: none !important;
    color: unset !important;
}

input:-webkit-autofill {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

.page {
    margin: 0 30px;
}

.rowcell {
    width: 420px;
}

.row {
    width: 860px;    
    flex:1 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tablestr {
		
        
		&:hover {
			background: #11B;
		} 
}

.page-table-row:hover {
    cursor: pointer;
    border-radius: 4px;
    background-color: rgba(11, 18, 24, 0.06);
}

.MuiTableCell-root.table-cell-btns {
    min-width: 206px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.header .title {
    display: flex;
    align-items: center;
}

.filter-bar {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

:focus {
    outline-style: none;
}

.search-rectangle {
    width: 500px;
    height: 46px;
    color: rgba(11, 18, 24, 0.46);
}

.select-rectangle {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    height: 46px;
    color: #0b1218;
}

.select-rectangle:hover {
    background-color: #e0e0e1;
}

.search-rectangle, .select-rectangle {
    border-radius: 23px;
    background-color: #f1f1f1;
    font-size: 17px;
    line-height: 1.29;
    letter-spacing: 0.73px;
}

.break-word {
    word-break: break-word;
}

.MuiDialog-paperWidthMd {
    width: 956px;
}

.MuiList-root.MuiMenu-list.MuiList-padding {
    padding: 0;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text {
    min-width: unset;
}

.MuiTableCell-root.MuiTableCell-head {
    font-size: 16px;
    line-height: 1.33;
    letter-spacing: 0.65px;
    color: rgba(11, 18, 24, 0.46);
    font-weight: normal;
}

.MuiTableCell-root.MuiTableCell-body .a,
.MuiTableCell-root.MuiTableCell-body a {
    cursor: pointer;
    -webkit-text-decoration: underline solid #a2a2a2 !important;
            text-decoration: underline solid #a2a2a2 !important;
}

.MuiTableCell-root.MuiTableCell-body .a:hover,
.MuiTableCell-root.MuiTableCell-body a:hover {
    -webkit-text-decoration-color: #000 !important;
            text-decoration-color: #000 !important;
}

.table-cell-img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
}

.required-icon {
    width: 24px;
    height: 24px;
}

.menu-drag-icon, .search-icon {
    color: rgba(11, 18, 24, 0.48);
}

.search-rectangle .MuiSvgIcon-root {
    color: rgba(11, 18, 24, 0.46);
    margin-left: 10px;
}

.select-rectangle .MuiSvgIcon-root {
    color: #0b1218;
    margin-right: 10px;
}

.select-rectangle .MuiNativeSelect-root.MuiNativeSelect-select.MuiInputBase-input.MuiInput-input.MuiInputBase-inputSelect {
    margin: 0 20px;
}

.select-rectangle .MuiNativeSelect-select:focus {
    background-color: rgba(0, 0, 0, 0) !important;
}

.status-indicator-green,
.status-indicator-gray,
.status-indicator-blue,
.status-indicator-pink {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
}
.status-indicator-green {
    background-color: #00d358;
}

.status-indicator-gray {
    background-color: #d1dce7;
}

.status-indicator-blue {
    background-color: #40b6f9
}

.status-indicator-pink {
    background-color: #ff9591;
}

.MuiPaper-root.MuiDialog-paper {
    padding: 36px;
}

.MuiDialogContent-root {
    padding: 8px 30px;
}

.ql-editor li {
    margin-top: .4em !important;
}

.ql-editor ol,
.ql-editor ul,
.ql-editor p {
    margin-bottom: .5em !important;
}

#emoji-palette {
    max-width: 500px !important;
}

.MuiGrid-item {
    padding: 0 0 20px 0;
}

.task-mini {
    display: inline-block;
    margin-right: 1em;
    width: 50px;
    height: 50px;
    resize: inherit;
    border-radius: 50%;
    background: #f1f1f1;
}

.task-mini-img-invert {
    margin: 9px;
    width: 32px;
    -webkit-filter: invert(100%);
            filter: invert(100%);
    resize: inherit;
}

.task-mini-img {
    margin: 9px;
    width: 32px;
    resize: inherit;
}

.menu-logo {
    cursor: pointer;
    margin: 30px 0 10px 16px;
    display: flex;
    min-height: 28px;
}

.menu-logo .icon {
    min-width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-logo .title {
    font-weight: bold;
    font-size: 1.2em;
}

.menu-logo.closed .icon {
    width: 100%;
    margin-left: -6px;
}

.menu-logo.closed .title {
    display: none;
}

.dialog-actions {
    justify-content: flex-start;
    margin-top: 36px;
}

.html-content h1 {
    font-size: 23px;
    padding: 0;
    margin: .3em 0;
}

.html-content h2 {
    font-size: 18px;
    padding: 0;
    margin: .2em 0;
}

.html-content ul {
    padding: 0;
    margin: .4em 10px;
}

.html-content p {
    padding: 0;
    margin: .4em 0;
}

.hint {
    font-size: 15px;
    font-weight: normal;
    color: #a2a2a2;
}

.MuiPopover-paper {
    max-height: calc(100vh - 80px) !important;
}

.MuiTab-root.tab-item {
    font-size: 28px;
    font-weight: bold;
    line-height: 1.21;
    letter-spacing: -0.48px;
    color: rgba(11, 18, 24, 0.24);
    text-transform: unset;
}

.Mui-selected.tab-item {
    color: #0b1218;
}

.MuiTabs-root.tab-list {
    margin-top: 20px;
}

.MuiTabs-indicator.tab-indicator {
    background-color: unset;
}

.MuiToggleButton-root {
    border: none;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.21;
    letter-spacing: -0.48px;
    color: rgba(11, 18, 24, 0.24);
    text-transform: none;
}

.MuiToggleButton-root:hover {
    background-color: unset;
}

.MuiToggleButton-root.Mui-selected {
    color: #0b1218;
    background-color: unset;
}

.MuiToggleButton-root.Mui-selected:hover {
    background-color: unset;
}


.calendar__body-row:first-of-type {
	width: 160px;
	flex: 0 0 auto;
	padding: 0 30px;
}

.calendar__body-row:nth-of-type(2) {
	max-width: 215px;
	flex: 1 1;
	min-width: 173px;
	padding: 0 23px;
}

.calendar__body-row:nth-of-type(3) {
	max-width: 372px;
	flex: 1 1;
	min-width: 260px;
	padding: 0 15px 0 0;
}

.calendar__body-row:nth-of-type(4) {
	max-width: 440px;
	min-width: 440px;
	padding-left: 33px;
}

.calendar__body-row:nth-of-type(5) {
	max-width: 286px;
	min-width: 286px;
	padding-left: 30px;
}

.calendar__body-row:nth-of-type(6) {
	max-width: 74px;
	min-width: 74px;
	flex: 0 0 auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** reset **/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	border: 0;
	font: inherit;
	font-size: 100%;
	vertical-align: baseline;
	margin: 0;
	padding: 0;
}

img {
	display: block;
	max-width: 100%;
     max-height: 100%;
    object-fit: contain;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
svg,
iframe {
	display: block;
}

ol,
ul {
	list-style: none;
	padding-left: 0;
}

blockquote,
q {
	quotes: none;
}


blockquote::before,
blockquote::after,
q::before,
q::after {
	content: none;
}



table {
	border-collapse: collapse;
	border-spacing: 0;
}

* {
	box-sizing: border-box;
}
*::after {
	box-sizing: border-box;
}
*::before {
	box-sizing: border-box;
}
* :invalid {
	box-shadow: none;
}
* :-moz-submit-invalid {
	box-shadow: none;
}
* :-moz-ui-invalid {
	box-shadow: none;
}
* :focus {
	outline: none;
}

.highlights {
	color: transparent;
}

a {
	color: inherit;
	display: inline-block;
	cursor: pointer;
	text-decoration: none;
}

strong,
b {
	font-weight: 700;
}

html,
body {
	height: 100%;
	width: 100%;
}

.slot_wrapper {
	display: flex;
	flex-direction: row;
	overflow: visible;
	flex-flow: column;
}

.section_wrapper {
	display: flex;
	flex-direction: row;
	width: 100%;
	overflow: visible;
	background: #eee;

	
}

.wrapper {
    padding-left: 85px;
    transition: 0.4s padding-left;
     display: flex;
  flex-direction: column;
	height: 125vh;
overflow: hidden;
}

.wrapper__open {
    padding-left: 315px;
    transition: 0.4s padding-left;
     display: flex;
  flex-direction: column;
	height: 125vh;
	overflow: hidden;
}

.appmenu__open {
    
    width: 300px;
}

.appmenu {
    
    width: 40px;
}

.shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9990;
    background-color: rgba(0, 0, 0, 0.5);
   text-align: center;
    
    
}

.shadow_circle {
     margin-top	: -25px;
     margin-left	: -25px;
   position: absolute; 
   top: 50%;       
    left: 50%;  
    z-index:9999;
   
}

header,
footer {
	flex: 0 0 auto;
}

main {
	flex: 1 0 auto;
}

body {
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	font-weight: 400;
	min-width: 320px;
	line-height: normal;
	color: #243341;
	background-color: #f4f6f8;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

h1 {
	font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
	font-size: 28px;
	font-weight: 700;
	line-height: normal;
}

h2 {
	font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
	font-size: 28px;
	font-weight: 700;
	line-height: normal;
}

h3 {
	font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
	font-size: 20px;
	font-weight: 700;
	line-height: normal;
}

h4 {
	font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
	font-size: 20px;
	font-weight: 700;
	line-height: normal;
}

h5 {
	font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: normal;
}

h6 {
	font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
	font-size: 16px;
	font-weight: 700;
	line-height: normal;
}

.visually--hidden {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px);
	padding: 0 !important;
	border: 0 !important;
	height: 1px !important;
	width: 1px !important;
	overflow: hidden;
}

.object-fit {
	width: 100%;
	height: 100%;
    object-fit: contain;
}

.is--cover {
	object-fit: cover;
}

.is--contain {
	object-fit: contain;
}

input,
select {
	font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
	font-weight: 400;
	font-size: 14px;
	color: #243341;
	padding: 0;
	border: 0;
	border-radius: 0;
	background-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	box-shadow: none;
}

textarea {
	width: 100%;
	height: 215px;
	overflow: hidden auto;
	resize: none;
	scrollbar-color: #6c7a7d transparent !important;
	scrollbar-width: thin !important;
}

textarea::-webkit-scrollbar {
	width: 8px;
}

textarea::-webkit-scrollbar-thumb {
	background-color: #6c7a7d;
	border-radius: 0;
}

/** placeholders **/
input::-moz-placeholder,
textarea::-moz-placeholder {
	font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
	font-weight: 400;
	opacity: 1;
	color: #6c7a7d;
	font-size: 14px;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
	font-weight: 400;
	opacity: 1;
	color: #6c7a7d;
	font-size: 14px;
}

input:not([type='submit']) {
	width: 100%;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
	color: transparent;
	outline: none;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
	color: transparent;
	outline: none;
}

select {
	cursor: pointer;
	padding-right: 30px !important;
	background: url(/static/media/select.ac97c04f.svg) no-repeat calc(100% - 15px) center;
}

@-moz-document url-prefix() {
	select {
		text-indent: -2px;
	}
}
option {
	padding: 0;
}

input[type='number'] {
	-webkit-appearance: none;
	-moz-appearance: textfield;
	appearance: none;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	appearance: none;
	-webkit-appearance: none;
}

input[type='submit']::-moz-focus-inner {
	border: 0;
}

/** button **/
button {
	font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
	padding: 3;
	border: 0;
	background-color: transparent;
	cursor: pointer;
	font-weight: 700;

}

.button_bordered {
	font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
	padding: 3;
	border: 1;
	background-color: transparent;
	cursor: pointer;
	font-weight: 700;

}


button::-moz-focus-inner {
	border: 0;
}

.section_head {
	background-color: #eee;
	justify-contennt: space-between;
	transition: 0.4s max-height;
	max-height:60px;
}

.section_arrow-down {
  width: 0; 
  height: 0; 
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid black;
}

.section_arrow-down-small {
  width: 0; 
  height: 0; 
  cursor: pointer;
  margin-top: 16px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black;
}

.section_arrow-up-small {
  width: 0; 
  height: 0; 
  cursor: pointer;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black;
}

.section_arrow-right {
  width: 0; 
  height: 0; 
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  
  border-left: 4px solid black;
}

.section_arrow {
  position: absolute;
  content: '';	
  width: 0;
  height: 0;
  border: .5em solid transparent;
  border-left-color: gray;
  transform-origin: 0 50%;
  transition: transform .25s;
  cursor: pointer;
}

.section_arrow_collapse {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: .5em solid transparent;
  border-left-color: gray;
  transform-origin: 0 50%;
  transition: transform .25s;
  cursor: pointer;
}


/** header **/

.header {
	padding: 30px;
}

.header__nav {
	padding: 40px 30px;
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	background-color: #0c2441;
	border-radius: 0px 30px 30px 0px;
	display: flex;
	flex-direction: column;
	width: 315px;
}

.header__logo {
	margin-left: 16px;
}

.header__nav-row {
	display: flex;
	align-items: center;
	margin-bottom: 36px;
	padding-left: 20px;
}

.profile {
	display: flex;
	align-items: center;
}


.text_n {
    white-space: pre-line;
}

.profile__img {
	flex: 0 0 auto;
	margin-right: 16px;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	background-color: lightgrey;
	border: 1px solid lightgray;
}

.profile__name {
	font-size: 14px;
	line-height: 17px;
	font-weight: 700;
}

span.profile .profile__name {
	font-weight: 600;
}

.block-dropdown,
.lang {
	position: relative;
}

.lang__btn {
	display: flex;
	align-items: center;
	font-weight: bold;
	font-size: 8px;
	line-height: 10px;
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #243341;
}

.lang__icon {
	flex: 0 0 auto;
	margin-left: 7px;
	stroke: #243341;
}

.lang__flag {
	flex: 0 0 auto;
	margin-right: 9px;
}

.block-dropdwon__btn {
	background-color: #fff;
	border-radius: 6px;
	border: 1px solid #156ae9;
	text-transform: uppercase;
	padding: 7px 27px 7px 10px;
	letter-spacing: 0.1em;
	font-weight: bold;
	font-size: 8px;
	color: #156ae9;
	position: relative;
}

.block-dropdwon__icon {
	position: absolute;
	right: 12px;
	top: 8px;
	stroke: #156ae9;
}

.dropdown {
	position: relative;
	padding-right: 40px !important;
}

.dropdown__icon {
	fill: none;
	position: absolute;
	top: 23px;
	right: 10px;
	stroke: #fff;
}

.dropdown:hover .dropdown__icon {
	stroke: #36b2f1;
}

.header__sublist {
	margin-top: 20px;
	padding-left: 30px;
	padding-bottom: 10px;
}

.header__iconitem {
    position: relative;
	padding-left: 2px;
    
}

.header__iconitem:not(:last-of-type) {
	margin-bottom: 20px;
}


.header__subitem {
	position: relative;
	padding-left: 32px;
}

.header__subitem:not(:last-of-type) {
	margin-bottom: 20px;
}

.header__subitem:before {
	content: '';
	position: absolute;
	left: 0;
	top: 4px;
	border-radius: 50%;
	width: 10px;
	height: 10px;
	border: 2px solid #fff;
}

.header__sublink {
	font-size: 13px;
	font-weight: 500;
}

.sandwich {
	display: flex;
	flex-direction: column;
	flex: 0 0 auto;
	width: 24px;
	height: 20px;
	justify-content: space-between;
}

.sandwich__part {
	height: 2px;
	width: 100%;
	background-color: #fff;
	border-radius: 5px;
}

.header__list {
	font-weight: 600;
	color: #fff;
	overflow: hidden auto;
	margin-right: -25px;
	padding-right: 25px;
	scrollbar-color: #fff transparent !important;
	scrollbar-width: thin !important;
}

.header__list::-webkit-scrollbar {
	width: 8px;
}

.header__list::-webkit-scrollbar-thumb {
	background-color: #fff;
	border-radius: 0;
}

.header__item:not(:last-of-type) {
	margin-bottom: 13px;
}

.header__link {
	padding: 13px 20px 14px;
	display: flex;
	align-items: flex-start;
	line-height: 143%;
	fill: #fff;
	border-radius: 8px;
}

.header__link span {
	padding-top: 2px;
}

.header__link:hover {
	color: #36b2f1;
	fill: #36b2f1;
	background-color: #0f2d51;
}

.svg-stroke {
	stroke: #fff;
}

.link--active .svg-stroke {
	stroke: #36b2f1;
}

.header__link:hover .svg-stroke {
	stroke: #36b2f1;
}

.header__link svg {
	flex: 0 0 auto;
	margin-right: 15px;
}

.link--active {
	color: #36b2f1;
	fill: #36b2f1;
	background-color: #0f2d51;
	pointer-events: none;
}

.link--active.dropdown .dropdown__icon {
	stroke: #36b2f1;
}

.header__blocks {
	display: flex;
	align-items: center;
	background-color: #fff;
	box-shadow: 0px 3px 35px rgba(36, 51, 65, 0.03);
	height: 60px;
	padding: 5px 10px;
}

.header__block {
	flex: 0 0 auto;
	padding: 0 20px;
}

.header__form {
	flex: 1 1;
}

.header__btn,
.header__form-submit {
	stroke: #243341;
}

.header__form-search {
	display: flex;
	align-items: center;
}

.header__form-submit {
	flex: 0 0 auto;
	margin-right: 15px;
}

.header__form-input {
	padding: 18px 0;
}

/** layout **/
.layout {
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 10px;

}

.layout_main {

}


.layout__contains {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
	padding-top: 34px;
}

.layout__btn {
	max-width: 282px;
	flex: 1 1;
}

.layout__contains .layout__title {
	margin-bottom: 0;
	margin-right: 30px;
}

.layout__title {
	margin-bottom: 10px;
	height: 50px;
}

.layout__wrap {
	overflow: hidden;
}

.layout__cols {
	display: flex;
	margin: 0 -15px;
}

.layout__col {
	padding: 0 15px;
	flex: 1 1;
}

.layout__col-aside {
	max-width: 472px;
	min-width: 330px;
}

.layout__block {
	background-color: #fff;
	box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
	border-radius: 15px;
	padding: 30px;
	display: flex;
	flex-direction: column;
}

.layout__block-title {
	font-weight: 600;
	font-size: 22px;
	margin-bottom: 30px;
}

.layout__block-monitor {
	background: #f4f6f8;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	width: 387px;
	height: 221px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 0;
	margin-bottom: 23px;
}

.layout__block-label {
	font-weight: 700;
	font-size: 8px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #243341;
	position: absolute;
	top: 17px;
	right: 13px;
	padding: 6px 8px;
	background-color: #fff;
	border-radius: 6px;
	z-index: 1;
}

.layout__inf-title {
	font-weight: 500;
	font-size: 18px;
	color: #243341;
	margin-bottom: 12px;
}

.layout__inf-list {
	display: flex;
	flex-wrap: wrap;
	margin: -3px;
	padding-bottom: 19px;
}

.layout__inf-item {
	padding: 3px;
}

.layout__inf-label {
	background: linear-gradient(90deg, #2ae9d2 -23.82%, #51d5cd 102.61%);
	border-radius: 6px;
	padding: 6px 12px;
	font-weight: 700;
	font-size: 8px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #fff;
	display: block;
}

.layout__inf-text {
	margin-bottom: 10px;
}

.layout__inf-blocks {
	display: flex;
	flex-wrap: wrap;
	padding-top: 9px;
	padding-bottom: 10px;
	margin: -7px;
}

.layout__inf-block {
	padding: 7px;
	width: calc(100% / 2);
}

.layout__inf-media {
	background-color: #fff;
	border: 1px solid #daebef;
	border-radius: 10px;
	padding: 14px 15px 16px;
	display: flex;
	align-items: flex-start;
}

.layout__inf-media svg {
	flex: 0 0 auto;
	margin-right: 16px;
	fill: #0f2d51;
	stroke: #0f2d51;
}

.layout__teams-title {
	font-weight: 600;
	font-size: 14px;
}

.layout__block-btns {
	margin-top: auto;
	padding-top: 30px;
}

.layout__block-btn:not(:last-of-type) {
	margin-bottom: 13px;
}

/** btn **/
.btn {
	display: block;
	width: 100%;
	font-size: 12px;
	text-align: center;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	background: linear-gradient(90deg, #4bebd8 -23.82%, #2b95ff 102.61%);
	border-radius: 10px;
	padding: 17px 15px;
	color: #fff;
}

.btn--border {
	color: #28b2d2;
	background: #fff;
	padding: 16px 15px;
	border: 1px solid #3dc7e8;
}

/** breadcrumbs **/
.breadcrumbs-wrap {
	overflow: hidden;
}

.breadcrumbs {
	display: flex;
	flex-wrap: wrap;
	margin: -6px;
	color: #6c7a7d;
	padding-bottom: 26px;
}

.breadcrumbs__item {
	padding: 6px 6px;
}

a.breadcrumbs__item {
	text-decoration: underline;
}

a.breadcrumbs__item:hover {
	text-decoration: none;
}

/** form **/
.form {
	display: flex;
	margin: 0 -30px;
}

.form__col {
	padding: 0 30px;
	flex: 1 1;
}

.form__col-small {
	max-width: 444px;
}

.form__label {
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 14px;
	display: inline-block;
	white-space: nowrap;
}

/** file **/
.file {
	position: relative;
}

.file__part {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 249px;
	background-color: #f9fafb;
	border-radius: 10px;
	border: 1px dashed #dbe6ef;
	text-align: center;
	cursor: pointer;
	font-size: 12px;
	color: #6c7a7d;
}

.file__part svg {
	fill: #3ec8e7;
	stroke: #3ec8e7;
	margin-bottom: 18px;
	overflow: visible;
}

.file__part span {
	color: #28b2d2;
	font-size: 14px;
	line-height: 17px;
	text-decoration: underline;
	padding-bottom: 8px;
}

.form__group {
	margin-bottom: 29px;
}

.form__input,
input,
select {
	background-color: #fff;
	border: 1px solid #a9bbcb;
	border-radius: 10px;
	padding: 15px;
}

input,
select {
	background-color: transparent;
}

.form__group-small {
	max-width: 100px;
}

.radio-buttons {
	border: 1px solid #3dc7e8;
	border-radius: 10px;
	background-color: #fff;
	display: flex;
}

.radio-button {
	position: relative;
	flex: 1 1;
}

.radio-button__part {
	display: block;
	padding: 15px;
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	color: #243341;
	cursor: pointer;
	border-radius: 10px;
}

.radio-button__checked {
	display: block;
	padding: 15px;
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	cursor: pointer;
	border-radius: 10px;
    background: linear-gradient(117.47deg, #4bebd8 2.19%, #2b95ff 91.03%);
	color: #fff;
}

.radio-button input:checked + .radio-button__part {
	background: linear-gradient(117.47deg, #4bebd8 2.19%, #2b95ff 91.03%);
	color: #fff;
}

.form__btn {
	display: flex;
	align-items: center;
	font-size: 14px;
	line-height: 17px;
	color: #28b2d2;
	font-weight: 400;
}

.form__btn span {
	white-space: nowrap;
	display: block;
	border-bottom: 1px solid #28b2d2;
}

.form__btn:hover span {
	border-bottom: 1px solid transparent;
}

.form__group-top {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin-top: -8px;
	margin-bottom: -8px;
	padding-bottom: 12px;
}

.form__group-body {
	padding: 15px 0;
	border-top: 1px solid #dbe6ef;
	border-bottom: 1px solid #dbe6ef;
}

.form__group-top .form__label {
	margin-bottom: 0;
	margin-right: 15px;
	padding: 8px 0;
}

.form__btn svg {
	margin-right: 10px;
	flex: 0 0 auto;
	stroke: #28b2d2;
	fill: #28b2d2;
	visibility: visible;
}

.date-wrap,
.time-wrap {
	width: 190px;
	display: flex;
	align-items: center;
	font-size: 14px;
	line-height: 17px;
	color: #243341;
}

.form__group-flex {
	display: flex;
	align-items: center;
}

.form__group-flex label {
	margin-right: 20px;
	flex: 0 0 auto;
	white-space: nowrap;
}

.time-wrap, .date-wrap {
	width: 300px;
}

.date-wrap label,
.date-wrap span,
.time-wrap label,
.time-wrap span {
	display: block;
	margin-right: 15px;
	flex: 0 0 auto;
    
}

.form__group-row {
	flex: 1 1;
	padding: 10px;
}

.form__group-rows {
	display: flex;
	margin: -10px;
	align-items: center;
}

.form__group-rows:not(:last-of-type) {
	padding-bottom: 5px;
}

.form__remove {
	fill: none;
}

/** calendar **/
.calendar {
	background-color: #fff;
	box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
	border-radius: 15px;
	padding-top: 10px;
	padding-bottom: 10px;
	height: 100%;
	overflow-y: hidden;
}

.calendar__head {
	display: flex;
	justify-content: center;
	border-bottom: 1px solid #dbe6ef;
	padding: 0 20px 20px;
	position: relative;
}

.calendar__nav {
	display: flex;
	align-items: center;
}

.calendar__nav-text {
	font-weight: 600;
	font-size: 22px;
	line-height: 27px;
	color: #243341;
	padding: 0 24px;
}

.calendar__nav-arrow {
	stroke: #243341;
	fill: none;
}

.calendar__dropdown {
	display: flex;
	align-items: center;
	position: absolute;
	top: 2px;
	right: 30px;
	fill: #243341;
}

.calendar__dropdown svg {
	flex: 0 0 auto;
	margin-right: 15px;
}

.calendar__dropdown span {
	font-size: 14px;
	line-height: 17px;
	color: #243341;
	text-decoration: underline;
	flex: 0 0 auto;
}

.calendar__body {
	
	overflow: hidden;
	height: 100%;
}

.calendar__tbody_full {
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 230px);
	touch-action: none;
	
}

.calendar__tbody_small {
	overflow-y: auto;
	
	overflow-x: hidden;
	height: calc(100vh - 570px);
	touch-action: none;
}

.calendar__body-rows {
	display: flex;
	align-items: center;
	padding: 16px 30px;
	margin: 0 -10px;
	position: relative;
}

.row-max-height {
	height: 100%;
}

.calendar__body-rows:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 15px;
	right: 15px;
	border-bottom: 1px solid #dbe6ef;
}

.calendar__body-head {
	font-weight: 600;
	font-size: 14px;
	color: #243341;
}

.calendar__body-row {
	padding: 0 15px;
	max-height: 100%;
	
}

.calendar__body-row:first-of-type {
	width: 140px;
	flex: 0 0 auto;
	padding: 0 20px;
}

.calendar__body-row:nth-of-type(2) {
	max-width: 120px;
	flex: 1 1;
	min-width: 120px;
	padding: 0 10px;
}

.calendar__body-row:nth-of-type(3) {
	max-width: 100px;
	flex: 1 1;
	min-width: 80px;
	padding: 0 20px 0 0;
}

.calendar__body-row:nth-of-type(4) {
	max-width: 600px;
	min-width: 500px;
	padding-left: 13px;
}

.calendar__body-row:nth-of-type(5) {
	max-width: 800px;
	min-width: 600px;
	padding-left: 20px;
}

.calendar__body-row:nth-of-type(6) {
	max-width: 250px;
	min-width: 200px;
	flex: 0 0 auto;
}

.calendar__body-time {
	font-weight: bold;
	font-size: 18px;
	letter-spacing: 0.15em;
	color: #243341;
	display: block;
	margin-bottom: 6px;
}

.calendar__body-label {
	font-weight: bold;
	font-size: 8px;
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #38beb3;
	padding: 5px 9px;
	border: 1px solid #4cd2c7;
	border-radius: 6px;
}

.calendar__body-label.is--gray {
	color: #a9bbcb;
	border-color: #a9bbcb;
}

.item-edit {
	border: 1px solid #DAEBEF;
	background: #F4F6F8;
	border-radius: 5px;
	box-sizing: border-box;
	padding: 8px 10px;
	color: #243341;

	cursor: pointer;
	line-height: 14px;
	transition: 0.2s;
	display: flex;
	flex-direction: 'row';
}

.calendar__body-block {
	/*min-height: 50px;*/
	/*max-height: 100%;*/
	/*display: flex;*/
	/*align-items: center;*/
	/*border: 1px dashed #a9bbcb;*/
	/*border-radius: 10px;*/
	/*padding: 7px;*/
	border: 1px solid #DAEBEF;
	background: #F4F6F8;
	border-radius: 5px;
	box-sizing: border-box;
	padding: 8px 10px;
	color: #243341;

	cursor: pointer;
	line-height: 14px;
	transition: 0.2s;
	display: block;
}



.calendar__body-block .input {
	padding: 0;
	background: none;
	box-shadow: none;
	border: none;
	font-size: 10px;
	font-weight: 700;
	margin-bottom: 4px;


}

.input-wrap {
	width: 100%;
	position: relative;
}

.input {
	padding: 10px 30px 10px 10px;
	background: #f4f6f8;
	border: 1px solid #daebef;
	box-shadow: -3px -2px 5px rgba(169, 187, 203, 0.15), inset -2px -2px 0px #e3edf5, inset 2px 2px 1px #ffffff;
	border-radius: 5px;
	display: block;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 12px;
	font-weight: 400;
}

.input-remove {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
}


.calendar-blocks__block:not(:last-of-type) {
	padding-bottom: 5px;
}

.calendar-block {
	background: #f4f6f8;
	border: 1px solid #daebef;
	border-radius: 10px;
	padding: 17px 12px;
	min-height: 58px;
	height: 100%;
	display: flex;
	align-items: center;
	padding-left: 44px;
	position: relative;
}

.calendar-block__lock {
	width: 16px;
	height: 20px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: -32px;
}

.calendar-block:before {
	content: '';
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: -50px;
	height: 1px;
	border-bottom: 1px solid #daebef;
	width: 50px;
}

.calendar-block__icon {
	position: absolute;
	top: 50%;
	left: 10px;
	transform: translateY(-50%);
}

.calendar-block__name {
	font-weight: bold;
	font-size: 12px;
}

.calendar-block__col {
	font-weight: 600;
	font-size: 10px;
	padding: 0 5px;
	flex: 0 0 auto;
	position: relative;
}

.calendar-block__col:before {
	content: '';
	position: absolute;
	left: 0;
	top: -17px;
	bottom: -17px;
	width: 1px;
	background-color: #daebef;
}

.calendar-block__select {
	padding: 0 0 0 2px;
	border: 0;
	width: auto;
	font-size: 12px;
	font-weight: 600;
}

.modal-calendar {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	margin-left: 345px;
	padding: 0;
	border: 2px solid #3dc7e8;
	box-shadow: 0px -3px 20px rgba(61, 199, 232, 0.08);
	border-bottom: 0;
	transition: 0.4s max-height;
}

.schedule-section {
	transition: 0.4s max-height;
	margin-bottom: 10px;
	padding: 0;
	
}

.schedule-section .section-collapse-icon {
	
	
}

.modal-calendar .calendar__body-rows::after {
	content: none;
}

.modal-calendar .calendar__body-rows {
	padding: 15px;
	align-items: flex-start;
}

.modal-calendar .calendar__body-head {
	font-weight: 500;
	font-size: 18px;
}

.calendar__body-btn {
	fill: none;
	font-size: 12px;
	color: #243341;
	font-weight: 400;
	display: flex;
	align-items: center;
}

.calendar__body-btn svg {
	flex: 0 0 auto;
	margin-right: 8px;
}

.calendar__body-btn:after {
	content: attr(data-show);
	text-decoration: underline;
}

.modal-calendar--open .calendar__body-btn:after {
	content: attr(data-hidden);
}

.calendar__body-add {
	display: flex;
	align-items: center;
	fill: rgba(62, 200, 231, 1);
	stroke: rgba(62, 200, 231, 1);
	font-weight: 500;
	font-size: 18px;
}

.calendar__body-add svg {
	flex: 0 0 auto;
	margin-left: 14px;
}

.calendar__list {
	max-height: 280px;
	padding-right: 20px;
	max-width: 422px;
	margin-top: 13px;
	overflow: hidden auto;
	scrollbar-color: #6c7a7d transparent !important;
	scrollbar-width: thin !important;
}

.calendar__list::-webkit-scrollbar {
	width: 8px;
}

.calendar__list::-webkit-scrollbar-thumb {
	background-color: #6c7a7d;
	border-radius: 0;
}

.calendar__item:not(:last-of-type) {
	margin-bottom: 10px;
}

.modal-calendar .calendar__body-row:first-of-type {
	padding-top: 6px;
}

.checkbox {
	position: relative;
	margin-top: 18px;
}

.checkbox__part {
	font-size: 14px;
	line-height: 17px;
	color: #243341;
	font-weight: 400;
	padding-left: 28px;
	position: relative;
	display: block;
	cursor: pointer;
}

.checkbox__part:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	border: 1px solid #a9bbcb;
	border-radius: 5px;
	background-color: #fff;
}

.checkbox input:checked + .checkbox__part:before {
	border-color: #4cd2c7;
}

.checkbox input:checked + .checkbox__part:after {
	content: '';
	position: absolute;
	width: 10px;
	height: 6px;
	border-bottom: 2px solid #4cd2c7;
	border-left: 2px solid #4cd2c7;
	left: 4px;
	top: 5px;
	transform: rotate(-45deg);
}

.modal-calendar--open .calendar__body-btn svg {
	transform: scaleY(-1);
}

.calendar--hidden {
	display: none;
}

.modal-calendar--open .calendar--hidden {
	display: block;
}

.conf-list {
	display: flex;
	flex-wrap: wrap;
	margin: -5px;
}

.conf-list__item {
	padding: 15px;
	width: calc(100% / 5);
}

.card {
	max-width: 100%;
	width: 100%;
	background: #ffffff;
	box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
	border-radius: 15px;
	height: 100%;
}

.card__top {
	height: 200px;
	width: 100%;
	position: relative;
}

.card__label {
	position: absolute;
	top: 20px;
	right: 20px;
	background-color: #fff;
	border-radius: 6px;
	padding: 6px 8px;
	font-weight: 700;
	font-size: 8px;
	line-height: 10px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}

.card__body {
	padding: 30px;
}

.card__body .layout__inf {
	margin-bottom: 16px;
}

.card__body .layout__inf-media {
	border: 0;
	padding: 6px 6px 6px 0;
}

.card__body .layout__inf-blocks {
	border: 1px solid #daebef;
	border-radius: 10px;
	margin: 0;
	padding: 8px 15px 9px;
	margin-top: 19px;
	flex-wrap: wrap;
}

.card__body .layout__inf-block {
	padding: 0;
	width: auto;
}

.card__link {
	display: flex;
	align-items: center;
}

.card__link svg {
	flex: 0 0 auto;
	margin-right: 18px;
}

.card__link:hover span {
	border-bottom: 1px solid transparent;
}

.card__link span {
	font-size: 14px;
	line-height: 17px;
	border-bottom: 1px solid #243341;
}

.card__title {
	font-weight: 500;
	font-size: 18px;
	margin-bottom: 12px;
	word-break: break-word;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.breadcrumbs + .layout__contains {
	padding-top: 0;
}

.tbl {
	background-color: rgba(255, 255, 255, 0.5);
	box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
	border-radius: 15px;
}

.tbl__body-row {
	background-color: #fff;
	box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
	border-radius: 15px;
}

.tbl__body-row:not(:last-of-type) {
	margin-bottom: 10px;
}

.tbl__top {
	padding: 22px 0;
}

.tbl__items {
	display: flex;
	padding: 0 15px;
}

.tbl__item {
	padding: 0 15px;
	flex: 1 1;
}

.tbl__item--name {
	max-width: 288px;
}

.tbl__item--level {
	max-width: 280px;
}

select {
	display: block;
	width: 100%;
}

.tbl__item--level select {
	max-width: 128px;
}

.tbl__item--city select {
	max-width: 158px;
}

.tbl__item--text {
	max-width: 350px;
}

.tbl__item--text input {
	max-width: 255px;
}

.tbl__item--date {
	max-width: 284px;
	position: relative;
	padding-right: 60px !important;
}

.tbl__item--date input {
	background: url(/static/media/picker.c9ea4fa3.svg) no-repeat calc(100% - 15px) center;
	max-width: 165px;
	padding-right: 40px;
}

.tbl__body .tbl__items {
	align-items: center;
}

.tbl__body .tbl__item {
	padding-top: 22px;
	padding-bottom: 22px;
}

.block-dropdown--green .block-dropdwon__btn {
	border: 1px solid #39dac7;
	color: #39dac7;
}

.block-dropdown--green .block-dropdwon__icon {
	stroke: #39dac7;
}

.block-dropdown--yellow .block-dropdwon__btn {
	border: 1px solid #d2bc7c;
	color: #d2bc7c;
}

.block-dropdown--yellow .block-dropdwon__icon {
	stroke: #d2bc7c;
}

.block-dropdown--purple .block-dropdwon__btn {
	border: 1px solid #5564e9;
	color: #5564e9;
}

.block-dropdown--purple .block-dropdwon__icon {
	stroke: #5564e9;
}

.block-dropdown--grey .block-dropdwon__btn {
	border: 1px solid #a9bbcb;
	color: #a9bbcb;
}

.block-dropdown--grey .block-dropdwon__icon {
	stroke: #a9bbcb;
}

.tbl__item-more {
	color: #a9bbcb;
	letter-spacing: 1px;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 30px;
}

.tbl__item-more:before {
	content: '···';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}




.sidebar {
  background-color: #0C2441;
  color: #fff;
  border-radius: 0 30px 30px 0;
  height: auto;
  min-height: 125vh;
  padding: 40px 20px;
  max-width: 315px;
  position: fixed;
  left: 0;
  top: 0;
  transition: 0.4s width;
}


.toggle {
  margin-right: 15px;
  cursor: pointer;
}
.toggle span {
  width: 24px;
  height: 2px;
  border-radius: 4px;
  background-color: #fff;
  display: block;
  margin-bottom: 7px;
}
.toggle span:last-child {
  margin-bottom: 0;
}

.sidebar-top {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
}

.link-icon {
  font-weight: 600;
  font-size: 14px;
  text-transform: none;
  display: flex;
  align-items: center;
  line-height: 20px;
  padding: 15px 30px;
}
.link-icon i {
  font-size: 24px;
  margin-right: 15px;
}

.list-submenu {
  padding: 30px;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: #0F2D51;
}
.list-submenu:last-child {
  margin-bottom: 0;
}
.list-submenu a {
  color: #fff;
}
.list-submenu .parent-submenu-link {
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
  padding-bottom: 40px;
  display: block;
}
.list-submenu .link-icon {
  padding: 0;
  padding-bottom: 25px;
}
.list-submenu .submenu a {
  font-size: 13px;
  font-weight: 400;
  padding-left: 30px;
  position: relative;
  display: block;
  line-height: 16px;
}
.list-submenu .submenu a::before {
  content: "";
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  transition: 0.2s;
  border-radius: 50%;
  position: absolute;
  left: 7px;
  top: 1.5px;
}
.list-submenu .submenu a:hover::before {
  border-color: #36B2F1;
}
.list-submenu .submenu li {
  margin-bottom: 20px;
  position: relative;
}
.list-submenu .submenu li:last-child {
  margin-bottom: 0;
}
.list-submenu ul li .submenu {
  padding-bottom: 40px;
}
.list-submenu ul li:last-child .submenu {
  padding-bottom: 0;
}

.sidebar nav a {
  color: #fff;
}
.sidebar nav a:hover {
  color: #36B2F1;
}

.icon-small {
  font-size: 20px !important;
}

.sidebar {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.sidebar nav ul .link-toggle-submenu {
  position: relative;
  padding-bottom: 0;
}

.sidebar nav ul .link-toggle-submenu + ul {
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.1s;
}
.sidebar nav ul .link-toggle-submenu.open + ul {
  max-height: 100000px;
  visibility: visible;
  opacity: 1;
}
.sidebar nav ul .link-toggle-submenu.open {
  padding-bottom: 40px;
}
.sidebar nav ul .link-toggle-submenu.open::after {
  transform: rotate(180deg);
}

#sidebar-nav {
  height: calc(100vh - 160px);
  overflow: auto;
} 

.simplebar-scrollbar::before {
  background-color: #36B2F1;
  
}

.simplebar-track.simplebar-vertical {
  width: 8px;
  right: 0;
  overflow-y: hidden;
}

.tabs-top {
    display: block;
}

.tabs-setting-conf-contents {
  padding: 45px 30px;
  background-color: #fff;
  border-radius: 0 0px 20px 20px;
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  margin-bottom: 20px;
}

.tabs-setting-conf {
  display: flex;
  align-items: flex-end;
}
.tabs-setting-conf .item {
  width: calc(25% - 20px);
  border-radius: 15px 15px 0 0;
  border: 1px solid #fff;
  background: linear-gradient(270deg, #F4F6F8 1.13%, #EFF2F4 98.87%);
  padding: 15px 20px;
  padding-right: 50px;
  position: relative;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
}
.tabs-setting-conf .item .icon-tab {
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 19px;
  background: #24D8C2;
  border-radius: 9px;
  margin-right: 15px;
  min-width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabs-setting-conf .item .icon-tab.offline {
  background: #3C4EED;
}
.tabs-setting-conf .item .icon-tab.gybrid {
  background: linear-gradient(90deg, #3C4EED 0%, #24D8C2 100%);
}
.tabs-setting-conf .item.active {
  padding-top: 25px;
  background: #fff;
}
.tabs-setting-conf .item .close-tab {
  margin-left: 20px;
  font-size: 13px;
  position: absolute;
  right: 20px;
  color: #A9BBCB;
  cursor: pointer;
}
.tabs-setting-conf .item .close-tab:hover {
  color: #243341;
}
.tabs-setting-conf .item-options {
  width: 80px;
  padding: 22.5px;
  font-size: 20px;
  color: #A9BBCB;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
} 
  
  
.block {
  background: #fff;
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  border-radius: 15px;
}
.block .header-block {
  padding: 30px;
  border-bottom: 1px solid #DBE6EF;
}
.block .header-block .title {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
}
.block .content-block {
  padding: 30px;
}

.settings-block {
  display: flex;
  justify-content: space-between;
}
.settings-block .item {
  width: calc(33% - 40px);
}
.settings-block .item .title {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 20px;
}
 
.file-label {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  border: 1px dashed #DBE6EF;
  background: #F9FAFB;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  flex-flow: column;
  margin-bottom: 20px;
}
.file-label .edit-btns {
  position: absolute;
  right: 8px;
  top: 16px;
  display: flex;
}
.file-label .edit-btns .edit, .file-label .edit-btns .delete {
  background: #FFFFFF;
  border: 1px solid #DAEBEF;
  box-sizing: border-box;
  border-radius: 6px;
  width: 32px;
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
}
.file-label .edit-btns .edit:hover, .file-label .edit-btns .delete:hover {
  background: #DAEBEF;
}
.file-label .edit-btns .edit {
  margin-right: 10px;
}
.file-label .edit-btns .delete {
  color: #FF5C5C;
}
.file-label label {
  color: #36B2F1;
  position: relative;
  cursor: pointer;
  line-height: 17px;
  display: block;
  text-align: center;
  margin-bottom: 10px;
}
.file-label label i {
  display: block;
  font-size: 70px;
  margin-bottom: 20px;
}
.file-label label span {
  text-decoration: underline;
}
.file-label .desc-file {
  font-size: 12px;
  color: #6C7A7D;
  display: block;
  text-align: center;
}
.file-label input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}

.textarea {
  width: 100%;
  height: 140px;
  border: 1px solid #A9BBCB;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 15px;
  font-family: "Montserrat", sans-serif;
  resize: none;
  color: #6C7A7D;
} 

.h1 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
}

.conf-detail {
  display: flex;
  justify-content: space-between;
}
.conf-detail .conf-img {
  margin-right: 30px;
  max-width: 25%;
  
}
.conf-detail .conf-info .title {
  margin-bottom: 30px;
}
.conf-detail .conf-info .tags {
  margin-bottom: 25px;
}
.conf-detail .conf-info .tags li {
  display: inline-block;
  padding: 6px;
  border-radius: 6px;
  background-color: #d5dee8;
  text-transform: uppercase;
  font-size: 8px;
  line-height: 10px;
  letter-spacing: 0.1em;
  font-weight: 700;
  margin-right: 10px;
}
.conf-detail .conf-info .tags li:last-child {
  margin-right: 0;
}
.conf-detail .conf-info .meta {
  display: flex;
}
.conf-detail .conf-info .meta .date, .conf-detail .conf-info .meta .address {
  border-radius: 10px;
  border: 1px solid #DAEBEF;
  padding: 14px 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}
.conf-detail .conf-info .meta .date i, .conf-detail .conf-info .meta .address i {
  font-size: 25px;
  margin-right: 20px;
}
.conf-detail .conf-info .meta .date span, .conf-detail .conf-info .meta .address span {
  display: block;
}
.conf-detail .conf-info .meta .date {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.conf-detail .conf-info .meta .date .day {
  display: flex;
  align-items: center;
  padding-right: 30px;
  margin-right: 30px;
  position: relative;
}
.conf-detail .conf-info .meta .date .day::after {
  content: "";
  width: 1px;
  height: 34px;
  background-color: #A9BBCB;
  opacity: 0.2;
  position: absolute;
  right: 0;
}
.conf-detail .conf-info .meta .address {
  display: flex;
  align-items: center;
  padding-right: 100px;
}

.conf-links {
  margin-top: 20px;
  margin-left: 120px;
}
.conf-links .btn-icon {
  margin-bottom: 45px;
  min-width: 280px;
}
.conf-links .btn-icon:last-child {
  margin-bottom: 0;
}
 
 
file-label_info {
    color: #36B2F1;
    position: relative;
    cursor: pointer;
    line-height: 17px;
    display: block;
    text-align: center;
    margin-bottom: 10px;
}

.member-top {
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
}
.member-top .conf-detail-member {
  padding: 30px;
  justify-content: flex-start;
  width: 100%;
}
.member-top .watch-online {
  width: 30%;
  min-width: 30%;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.member-top .watch-online a {
  color: #fff;
  text-align: center;
}
.member-top .watch-online a:hover {
  color: #36B2F1;
}
.member-top .watch-online a i {
  font-size: 60px;
}
.member-top .watch-online a span {
  display: block;
  margin-top: 20px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.5;
}

.btn-border {
  padding: 15px 25px;
  border: 1px solid #243341;
  color: #243341;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  text-transform: uppercase;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
}
.btn-border i {
  font-size: 20px;
  margin-right: 15px;
}
.btn-border:hover {
  color: #fff;
  background: #243341;
}

.btn-member {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.btn-member button {
  margin-right: 15px;
}
.btn-member .btn-gradient {
  padding: 20px 65px;
}

.btn-gradient {
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  background: linear-gradient(270deg, #1994D2 -2.55%, #36B2F1 104.08%);
  border-radius: 10px;
  text-align: center;
  padding: 20px 30px;
} 

.block-default {
  background-color: #fff;
  margin-top: 20px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
}
.block-default .title {
  font-size: 22px;
  line-height: 27px;
  font-weight: 600;
  margin-bottom: 20px;
}
.block-default p {
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 20px;
}
.block-default .title-small {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
}

.partners ul {
  display: flex;
}
.partners ul li {
  margin-right: 65px;
}
.partners ul li:last-child {
  margin-right: 0;
} 

.tab-switcher {
  border: 1px solid #1994D2;
  border-radius: 10px;
  margin-bottom: 40px;
  display: inline-block;
}
.tab-switcher ul {
  display: flex;
  position: relative;
}
.tab-switcher ul li {
  padding: 16px 27px;
  border-radius: 9px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  background-color: #fff;
}
.tab-switcher ul li.active {
  background: linear-gradient(270deg, #1994D2 -2.55%, #36B2F1 104.08%);
  color: #fff;
}

.list-schedule .item {
  border-radius: 10px;
  border: 1px solid #DAEBEF;
  background-color: #F4F6F8;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.list-schedule .item:last-child {
  margin-bottom: 0;
}
.list-schedule .item .time {
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  letter-spacing: 0.15em;
  padding-right: 20px;
  margin-right: 20px;
  position: relative;
}
.list-schedule .item .time::after {
  content: "";
  width: 1px;
  height: calc(100% + 20px);
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #DAEBEF;
  position: absolute;
  right: 0;
}
.list-schedule .item.inactive {
  color: #6C7A7D;
}
.list-schedule .item .theme {
  width: 100%;
}
.list-schedule .item .title-theme {
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
  padding-bottom: 5px;
}
.list-schedule .item .desc-theme {
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
}
.list-schedule .item .favorite {
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.list-schedule .item .favorite::before {
  font-size: 30px;
  font-family: "naviconf";
}
.list-schedule .item .favorite path {
  fill: #fff0;
}
.list-schedule .item .favorite.active path {
  fill: #FAFF00;
}



@font-face {
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  

  .icon-wi-fi-stroke:before {
    content: "\e932";
  }
  .icon-lock:before {
    content: "\e930";
  }
  .icon-minus:before {
    content: "\e92f";
  }
  .icon-search-two:before {
    content: "\e92e";
  }
  .icon-pluse:before {
    content: "\e92b";
  }
  .icon-reload:before {
    content: "\e92d";
  }
  .icon-close-border:before {
    content: "\e931";
  }
  .icon-plus-border:before {
    content: "\e92c";
  }
  .icon-options:before {
    content: "\e919";
  }
  .icon-gybrid:before {
    content: "\e900";
  }
  .icon-online:before {
    content: "\e901";
  }
  .icon-arror-send:before {
    content: "\e902";
  }
  .icon-arrow-down:before {
    content: "\e903";
  }
  .icon-arrow-left:before {
    content: "\e904";
  }
  .icon-arrow-right:before {
    content: "\e905";
  }
  .icon-arrow-up:before {
    content: "\e906";
  }
  .icon-calendar:before {
    content: "\e907";
  }
  .icon-calendar2:before {
    content: "\e908";
  }
  .icon-chat:before {
    content: "\e909";
  }
  .icon-check-icon:before {
    content: "\e90a";
  }
  .icon-close:before {
    content: "\e90b";
  }
  .icon-dashboard:before {
    content: "\e90c";
  }
  .icon-database:before {
    content: "\e90d";
  }
  .icon-delete:before {
    content: "\e90e";
  }
  .icon-doc:before {
    content: "\e90f";
  }
  .icon-edit:before {
    content: "\e910";
  }
  .icon-eye:before {
    content: "\e911";
  }
  .icon-gear:before {
    content: "\e912";
  }
  .icon-grid_view:before {
    content: "\e913";
  }
  .icon-headphones:before {
    content: "\e914";
  }
  .icon-help:before {
    content: "\e915";
  }
  .icon-icon_picture:before {
    content: "\e916";
  }
  .icon-list_view:before {
    content: "\e917";
  }
  .icon-live:before {
    content: "\e918";
  }
  .icon-male:before {
    content: "\e91a";
  }
  .icon-navigator:before {
    content: "\e91b";
  }
  .icon-partners:before {
    content: "\e91c";
  }
  .icon-payment:before {
    content: "\e91d";
  }
  .icon-phone:before {
    content: "\e91e";
  }
  .icon-placeholder:before {
    content: "\e91f";
  }
  .icon-play:before {
    content: "\e920";
  }
  .icon-plus:before {
    content: "\e921";
  }
  .icon-program:before {
    content: "\e922";
  }
  .icon-search:before {
    content: "\e923";
  }
  .icon-settings:before {
    content: "\e924";
  }
  .icon-speacker:before {
    content: "\e925";
  }
  .icon-star:before {
    content: "\e926";
  }
  .icon-team-1:before {
    content: "\e927";
  }
  .icon-team:before {
    content: "\e928";
  }
  .icon-time:before {
    content: "\e929";
  }
  .icon-wi-fi:before {
    content: "\e92a";
  }
  
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    border: none
}

/* Хак для увеличения шрифта, чтоб перекрыть MuiGrid-container-1 */
html {
    font-size: 16px !important;
}

.h100 {
    height: 100%;
}

.flex {
    display: flex;
}

.site-menu {
    height: 100%;
    background: #f8f8f8;
}

.site-content .content {
    margin: 10px;
}

.ql-editor {
    min-height: 190px !important;
    max-height: 190px;
    overflow: hidden;
    overflow-y: scroll;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

/*!
 * Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto;
}


.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}


.row {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@font-face {
    font-family: 'naviconf';
    src:  url(/static/media/naviconf.25dc4719.eot);
    src:  url(/static/media/naviconf.25dc4719.eot#iefix) format('embedded-opentype'),
    url(/static/media/naviconf.11030268.ttf) format('truetype'),
    url(/static/media/naviconf.261b4b8d.woff) format('woff'),
    url(/static/media/naviconf.22775871.svg#naviconf) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'naviconf' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-wi-fi-stroke:before {
    content: "\e932";
}
.icon-lock:before {
    content: "\e930";
}
.icon-minus:before {
    content: "\e92f";
}
.icon-search-two:before {
    content: "\e92e";
}
.icon-pluse:before {
    content: "\e92b";
}
.icon-reload:before {
    content: "\e92d";
}
.icon-close-border:before {
    content: "\e931";
}
.icon-plus-border:before {
    content: "\e92c";
}
.icon-options:before {
    content: "\e919";
}
.icon-gybrid:before {
    content: "\e900";
}
.icon-online:before {
    content: "\e901";
}
.icon-arror-send:before {
    content: "\e902";
}
.icon-arrow-down:before {
    content: "\e903";
}
.icon-arrow-left:before {
    content: "\e904";
}
.icon-arrow-right:before {
    content: "\e905";
}
.icon-arrow-up:before {
    content: "\e906";
}
.icon-calendar:before {
    content: "\e907";
}
.icon-calendar2:before {
    content: "\e908";
}
.icon-chat:before {
    content: "\e909";
}
.icon-check-icon:before {
    content: "\e90a";
}
.icon-close:before {
    content: "\e90b";
}
.icon-dashboard:before {
    content: "\e90c";
}
.icon-database:before {
    content: "\e90d";
}
.icon-delete:before {
    content: "\e90e";
}
.icon-doc:before {
    content: "\e90f";
}
.icon-edit:before {
    content: "\e910";
}
.icon-eye:before {
    content: "\e911";
}
.icon-gear:before {
    content: "\e912";
}
.icon-grid_view:before {
    content: "\e913";
}
.icon-headphones:before {
    content: "\e914";
}
.icon-help:before {
    content: "\e915";
}
.icon-icon_picture:before {
    content: "\e916";
}
.icon-list_view:before {
    content: "\e917";
}
.icon-live:before {
    content: "\e918";
}
.icon-male:before {
    content: "\e91a";
}
.icon-navigator:before {
    content: "\e91b";
}
.icon-partners:before {
    content: "\e91c";
}
.icon-payment:before {
    content: "\e91d";
}
.icon-phone:before {
    content: "\e91e";
}
.icon-placeholder:before {
    content: "\e91f";
}
.icon-play:before {
    content: "\e920";
}
.icon-plus:before {
    content: "\e921";
}
.icon-program:before {
    content: "\e922";
}
.icon-search:before {
    content: "\e923";
}
.icon-settings:before {
    content: "\e924";
}
.icon-speacker:before {
    content: "\e925";
}
.icon-star:before {
    content: "\e926";
}
.icon-team-1:before {
    content: "\e927";
}
.icon-team:before {
    content: "\e928";
}
.icon-time:before {
    content: "\e929";
}
.icon-wi-fi:before {
    content: "\e92a";
}

.header {
  padding: 30px;
  position: fixed;
  z-index: 100;
}

.header-row {
  background-color: #fff;
  box-shadow: 0px 3px 35px rgba(36, 51, 65, 0.03);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  position: relative;
}

.header-search-form {
  width: 60%;
  position: relative;
  display: flex;
}
.header-search-form::before {
  content: "\e923";
  font-family: "naviconf";
  font-size: 22px;
  margin-right: 15px;
}
.header-search-form input {
  padding: 0;
  border: none;
  width: 100%;
  display: block;
  background-color: #fff;
  font-family: "Montserrat", sans-serif;
  color: #130F26;
  font-size: 14px;
}
.header-search-form ::-webkit-input-placeholder {
  color: #130F26;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.header-search-form :-ms-input-placeholder {
  color: #130F26;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.header-search-form ::placeholder {
  color: #130F26;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.right-header-info {
  display: flex;
  align-items: center;
}

.account-header {
  display: flex;
  align-items: center;
  position: relative;
}
.account-header .account-header-img {
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}
.account-header .account-header-name {
  font-weight: bold;
  color: #243341;
}

.account-header-type-change {
  margin-left: 20px;
  margin-right: 35px;
}
.account-header-type-change .account-header-type-change__list {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  padding: 30px 30px;
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.09);
  border-radius: 15px;
  right: 20px;
  transition: 0.2s;
  top: -20px;
  background-color: #fff;
}
.account-header-type-change .account-header-type-change__list a {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 8px;
  color: #243341;
}
.account-header-type-change .account-header-type-change__list li {
  margin-bottom: 15px;
}
.account-header-type-change .account-header-type-change__list li:last-child {
  margin-bottom: 0;
}
.account-header-type-change .account-header-type-change__current {
  font-size: 8px;
  color: #243341;
  font-weight: bold;
  text-transform: uppercase;
  padding: 7px 10px;
  border-radius: 6px;
  border: 1px solid;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: 0.2s;
}
.account-header-type-change .account-header-type-change__current::after {
  content: "\e903";
  font-family: "naviconf";
  margin-left: 8px;
}
.account-header-type-change .account-header-type-change__current:hover {
  border-color: #243341;
  background-color: #243341;
  color: #fff;
}
.account-header-type-change .account-header-type-change__current.open + .account-header-type-change__list {
  opacity: 1;
  visibility: visible;
}

.lang-switcher {
  font-size: 8px;
  color: #243341;
  font-weight: bold;
}
.lang-switcher img {
  border-radius: 3px;
  overflow: hidden;
  margin-right: 10px;
  max-width: 32px;
}
.lang-switcher .lang-switcher__current {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.lang-switcher .lang-switcher__current::after {
  content: "\e903";
  font-family: "naviconf";
  margin-left: 7px;
}
.lang-switcher .lang-switcher__list {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  height: auto;
  background-color: #fff;
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.09);
  top: 0;
  right: 0;
  padding: 30px;
  transition: 0.2s;
  width: 130px;
  border-radius: 15px;
}
.lang-switcher .lang-switcher__list a {
  font-size: 8px;
  color: #243341;
  font-weight: bold;
}
.lang-switcher .lang-switcher__list li {
  margin-bottom: 15px;
}
.lang-switcher .lang-switcher__list li:last-child {
  margin-bottom: 0;
}
.lang-switcher .lang-switcher__current.open + .lang-switcher__list {
  opacity: 1;
  visibility: visible;
}

a {
  text-decoration: none;
  transition: 0.2s;
  outline: none !important;
}

.header, article {
  padding-left: 345px;
  width: 100%;
  padding-right: 30px;
}

article {
  padding-top: 140px;
}

.contstructor-page {
  padding-right: 0;
  
}

.toggle {
  margin-right: 15px;
  cursor: pointer;
}
.toggle span {
  width: 24px;
  height: 2px;
  border-radius: 4px;
  background-color: #fff;
  display: block;
  margin-bottom: 7px;
}
.toggle span:last-child {
  margin-bottom: 0;
}

.sidebar-top {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
}

.link-icon {
  font-weight: 600;
  font-size: 14px;
  text-transform: none;
  display: flex;
  align-items: center;
  line-height: 20px;
  padding: 15px 30px;
}
.link-icon i {
  font-size: 24px;
  margin-right: 15px;
}

.list-submenu {
  padding: 30px;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: #0F2D51;
}
.list-submenu:last-child {
  margin-bottom: 0;
}
.list-submenu a {
  color: #fff;
}
.list-submenu .parent-submenu-link {
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
  padding-bottom: 40px;
  display: block;
}
.list-submenu .link-icon {
  padding: 0;
  padding-bottom: 25px;
}
.list-submenu .submenu a {
  font-size: 13px;
  font-weight: 400;
  padding-left: 30px;
  position: relative;
  display: block;
  line-height: 16px;
}
.list-submenu .submenu a::before {
  content: "";
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  transition: 0.2s;
  border-radius: 50%;
  position: absolute;
  left: 7px;
  top: 1.5px;
}
.list-submenu .submenu a:hover::before {
  border-color: #36B2F1;
}
.list-submenu .submenu li {
  margin-bottom: 20px;
  position: relative;
}
.list-submenu .submenu li:last-child {
  margin-bottom: 0;
}
.list-submenu ul li .submenu {
  padding-bottom: 40px;
}
.list-submenu ul li:last-child .submenu {
  padding-bottom: 0;
}

.sidebar nav a {
  color: #fff;
}
.sidebar nav a:hover {
  color: #36B2F1;
}

.icon-small {
  font-size: 20px !important;
}

.sidebar {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.sidebar nav ul .link-toggle-submenu {
  position: relative;
  padding-bottom: 0;
}



.sidebar nav ul .link-toggle-submenu + ul {
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.1s;
}
.sidebar nav ul .link-toggle-submenu.open + ul {
  max-height: 100000px;
  visibility: visible;
  opacity: 1;
}
.sidebar nav ul .link-toggle-submenu.open {
  padding-bottom: 40px;
}

#sidebar-nav {
  height: calc(100vh - 190px);
  overflow: auto;
}

.simplebar-scrollbar::before {
  background-color: #36B2F1;
}

.simplebar-track.simplebar-vertical {
  width: 8px;
  right: 0;
}

body {
  background-color: #F4F6F8;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #243341;
  font-weight: 400;
  min-width: 1600px;
}

button {
  padding: 0;
  margin: 0;
  background-color: #fff0;
  border: none;
}

.margin-null {
  margin: 0;
}

.breadcrumbs {
  display: flex;
  margin-bottom: 45px;
}
.breadcrumbs a {
  text-decoration: underline;
  color: #6C7A7D;
  position: relative;
  padding-right: 20px;
}
.breadcrumbs a::after {
  content: "";
  position: absolute;
  width: 0;
  right: 5px;
  top: 5px;
  height: 0;
  border-style: solid;
  border-width: 3px 0 3px 6px;
  border-color: transparent transparent transparent #6C7A7D;
}
.breadcrumbs a:last-child::after {
  display: none;
}

.title-page {
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
}

.tabs-nav {
  display: flex;
  height: 100%;
  flex-flow: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  transition: 0.2s;
  width: 80px;
}
.tabs-nav .top-links, .tabs-nav .bottom-links {
  position: relative;
}
.tabs-nav .top-links li, .tabs-nav .bottom-links li {
  padding: 20px 17px;
  height: 80px;
  position: relative;
  cursor: pointer;
  z-index: 2;
}
.tabs-nav .top-links a, .tabs-nav .top-links li, .tabs-nav .bottom-links a, .tabs-nav .bottom-links li {
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  color: #243341;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
}
.tabs-nav .top-links a:hover, .tabs-nav .top-links li:hover, .tabs-nav .bottom-links a:hover, .tabs-nav .bottom-links li:hover {
  color: #36B2F1;
}
.tabs-nav .top-links a i, .tabs-nav .top-links li i, .tabs-nav .bottom-links a i, .tabs-nav .bottom-links li i {
  font-size: 22px;
  display: block;
  padding-bottom: 5px;
}
.tabs-nav .top-links a span, .tabs-nav .top-links li span, .tabs-nav .bottom-links a span, .tabs-nav .bottom-links li span {
  display: block;
  text-align: center;
  word-wrap: break-word;
  line-height: 1.2;
}
.tabs-nav .top-links .top-links__active, .tabs-nav .bottom-links .top-links__active {
  position: absolute;
  top: 0px;
  left: -5px;
  width: 85px;
  height: 80px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  border-radius: 15px 0 0 15px;
  z-index: 1;
}
.tabs-nav .top-links .top-links__active::before, .tabs-nav .top-links .top-links__active::after, .tabs-nav .bottom-links .top-links__active::before, .tabs-nav .bottom-links .top-links__active::after {
  content: "";
  width: 23px;
  height: 23px;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;

  background-color: #fff0;
  position: absolute;
  bottom: -23px;
  transition: 0.2s;
  right: 0;
}
.tabs-nav .top-links .top-links__active::before, .tabs-nav .bottom-links .top-links__active::before {
  top: -23px;
  bottom: auto;
  transform: rotateX(180deg);
}
.tabs-nav .top-links .tab-link.active:nth-child(1) ~ .top-links__active, .tabs-nav .bottom-links .tab-link.active:nth-child(1) ~ .top-links__active {
  top: 0;
}
.tabs-nav .top-links .tab-link.active:nth-child(1) ~ .top-links__active::before, .tabs-nav .bottom-links .tab-link.active:nth-child(1) ~ .top-links__active::before {
  background-image: none;
  background-color: #fff;
  top: 0;
  right: -15px;
}
.tabs-nav .top-links .tab-link.active:nth-child(2) ~ .top-links__active, .tabs-nav .bottom-links .tab-link.active:nth-child(2) ~ .top-links__active {
  top: 80px;
}
.tabs-nav .top-links .tab-link.active:nth-child(3) ~ .top-links__active, .tabs-nav .bottom-links .tab-link.active:nth-child(3) ~ .top-links__active {
  top: 160px;
}
.tabs-nav .bottom-links a i {
  color: #36B2F1;
  font-size: 30px;
}
.tabs-nav .bottom-links a span {
  word-wrap: normal;
}
.tabs-nav .toggle-tabs {
  width: 35px;
  height: 35px;
  border: 1px solid #DBE6EF;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  justify-content: center;
  font-size: 10px;
  color: #243341;
  margin: auto;
}
.tabs-nav .toggle-tabs i {
  position: relative;
}
.tabs-nav .toggle-tabs:hover {
  background-color: #DBE6EF;
}

.constructor-tabs {
  position: relative;
  overflow: hidden;
  height: 100vh;


}


.constructor-tabs .tabs-content {
  width: 0;
  background-color: #fff;
  border-radius: 15px;
  transition: width cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s, padding cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  box-sizing: border-box;
  visibility: hidden;
  position: relative;
  opacity: 0;
}
.constructor-tabs .tabs-content .tab {
  min-width: 230px;
  width: 230px;
  position: absolute;
  top: 30px;
  opacity: 0;
  visibility: hidden;
  transition: opacity cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
}
.constructor-tabs .tabs-content .tab.active {
  position: relative;
  top: 0;
  opacity: 1;
  visibility: visible;
}
.constructor-tabs.open .top-links__active {
  opacity: 1;
  visibility: visible;
}
.constructor-tabs.open .tabs-content {
  padding: 30px;
  transition: width cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s, padding cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  width: 360px;
  visibility: visible;
  display: block;
  opacity: 1;
}
.constructor-tabs.open .toggle-tabs i {
  left: 2px;
  transform: rotate(180deg);
}
.constructor-tabs.open .tabs-nav {
  border-radius: 15px 0 0 15px;
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04), inset -30px 0 80px rgba(36, 51, 65, 0.08);
}

.search-tab .form {
  display: flex;
  align-items: center;
  margin: 0;
}
.search-tab .form button {
  color: #36B2F1;
  border: none;
  background-color: #fff0;
}
.search-tab .form button i {
  font-size: 30px;
}
.search-tab .form i {
  font-size: 17px;
  margin-right: 10px;
}
.search-tab .form input {
  border: none;
  background-color: #fff0;
  font-size: 12px;
  color: #130F26;
  line-height: 1.2;
}
.search-tab .form ::-webkit-input-placeholder {
  color: #130F26;
  font-size: 12px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}
.search-tab .form :-ms-input-placeholder {
  color: #130F26;
  font-size: 12px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}
.search-tab .form ::placeholder {
  color: #130F26;
  font-size: 12px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}

.list-tab {
  margin-top: 30px;
}
.list-tab .item-list {
  border: 1px solid #DAEBEF;
  background: #F4F6F8;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 8px 10px;
  color: #243341;
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 4px;
  cursor: pointer;
  line-height: 14px;
  transition: 0.2s;
  display: block;
}
.list-tab .item-list:last-child {
  margin-bottom: 0;
}
.list-tab .item-list:hover {
  color: #36B2F1;
}

.head-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.head-title-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.filter-date {
  padding-right: 170px;
  display: flex;
  align-items: center;
}
.filter-date .title-date {
  padding: 0 60px;
  font-size: 22px;
  font-weight: bold;
}
.filter-date .title-date i {
  margin-right: 15px;
}
.filter-date .filter-arrows {
  padding: 0;
  border: none;
  background-color: #fff0;
  font-size: 18px;
}

.constructor-row {
  padding-bottom: 1px;
  
  display: flex;
  display: -ms-flexbox;
  min-height: 100%;
  justify-content: space-between;
}
.constructor-row .constructor-table {
  width: calc(100% - 100px);
  margin-right: 20px;
  transition: .2s;
}

.contstructor-page.open-tabs .constructor-table {
  width: calc(100% - 350px);
}

.constructor-table .line-constructor.title-constructor .move {
  background-image: url(/static/media/move.0771215c.svg);
  background-position: center;
  cursor: pointer;
  background-repeat: no-repeat;
  transition: 0.2s;
}

.constructor-row .constructor-tabs {
  width: auto;
  max-width: 370px;
  margin-top: 100px;
  display: flex;
  justify-content: flex-end;
}

.constructor-table .table {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  margin-top: 30px;
  padding-bottom: 5px;
  overflow: auto;
  height: 100vh;
}

.constructor-table .body-contstructor {
  overflow: auto;
}
.constructor-table .head-contstructor, .constructor-table .line-constructor {
  display: flex;
}
.constructor-table .head-contstructor .time, .constructor-table .line-constructor .time {
  width: 120px;
  max-width: 120px;
  min-width: 120px;
  box-sizing: border-box;
}
.constructor-table .head-contstructor .move, .constructor-table .line-constructor .move {
  width: 30px;
  min-width: 30px;
  background-color: #F4F6F8;
}
.constructor-table .head-contstructor .duration, .constructor-table .line-constructor .duration {
  width: 10%;
}
.constructor-table .head-contstructor .theme, .constructor-table .line-constructor .theme {
  width: 30%;
}
.constructor-table .head-contstructor .speaker, .constructor-table .line-constructor .speaker {
  width: 30%;
}
.constructor-table .head-contstructor .room, .constructor-table .line-constructor .room {
  width: 15%;
}
.constructor-table .head-contstructor .options, .constructor-table .line-constructor .options {
  max-width: 50px;
  width: 50px;
}
.constructor-table .line-constructor {
  border-bottom: 1px solid #DAEBEF;
}
.constructor-table .line-constructor .item-add {
  font-size: 12px;
  padding-left: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 15px;
  line-height: 15px;
  color: #6C7A7D;
  cursor: pointer;
  font-weight: 400;
  position: relative;
}
.constructor-table .line-constructor .item-add::after {
  content: "\e903";
  font-family: "naviconf";
  font-size: 10px;
  color: #243341;
  position: absolute;
  right: 16px;
}
.constructor-table .line-constructor > div {
  padding: 5px;
}
.constructor-table .line-constructor .theme {
  border-left: 1px solid #DAEBEF;
  border-right: 1px solid #DAEBEF;
}
.constructor-table .line-constructor .speaker {
  border-right: 1px solid #DAEBEF;
}
.constructor-table .line-constructor .room {
  border-right: 1px solid #DAEBEF;
}
.constructor-table .line-constructor .time {
  padding-top: 10px;
  text-align: center;
}
.constructor-table .line-constructor .name-parent {
  padding: 10px 0;
  width: calc(100% - 150px);
  display: flex;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
}
.constructor-table .line-constructor .name-parent .left-name {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.constructor-table .line-constructor .name-parent .left-name:hover .collapse-icon {
  background-color: #DBE6EF;
}
.constructor-table .line-constructor .name-parent .add-theme-button {
  min-width: 100px;
  display: flex;
  align-items: center;
  margin-right: 11px;
  cursor: pointer;
}
.constructor-table .line-constructor .name-parent .add-theme-button i {
  font-size: 30px;
  margin-right: 10px;
  color: #36B2F1;
}
.constructor-table .line-constructor .name-parent .add-theme-button span {
  display: block;
  text-align: center;
  max-width: 50px;
  font-size: 9px;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  line-height: 12px;
}
.constructor-table .line-constructor .name-parent .add-theme-button:hover span {
  color: #36B2F1;
}
.constructor-table .line-constructor .name-parent .collapse-icon {
  width: 35px;
  height: 35px;
  border: 1px solid #DBE6EF;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  font-size: 8px;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  margin-right: 15px;
  margin-left: 20px;
}
.constructor-table .line-constructor.title-constructor {
  border-bottom: 1px solid #DAEBEF;
}
.constructor-table .line-constructor.title-constructor .move {

  background-position: center;
  cursor: pointer;
  background-repeat: no-repeat;
  transition: 0.2s;
}
.constructor-table .line-constructor.title-constructor .move:hover {
  background-color: #e9ecee;
}
.constructor-table .line-constructor.title-constructor .collapse-icon i {
  position: relative;
  transform: rotate(90deg);
}
.constructor-table .line-constructor.title-constructor.open .collapse-icon i {
  transform: none;
}
.constructor-table .line-constructor .options-button {
  text-align: center;
  color: #A9BBCB;
  font-size: 20px;
  height: 100%;
  line-height: 20px;
  display: flex;
  align-items: center;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  justify-content: center;
  cursor: pointer;
}
.constructor-table .line-constructor .options-button:hover {
  color: #243341;
}
.constructor-table .head-contstructor {
  padding: 23px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  border-bottom: 1px solid #DBE6EF;
}
.constructor-table .head-contstructor .theme {
  display: flex;
  align-items: center;
}
.constructor-table .head-contstructor .time {
  padding-left: 30px;
}
.constructor-table .head-contstructor .duration {
  padding-left: 40px;
  width: calc(13% + 40px);
}
.constructor-table .head-contstructor .speaker {
  display: flex;
  align-items: center;
}
.constructor-table .head-contstructor .room {
  display: flex;
  align-items: center;
}
.constructor-table .head-contstructor .time {
  width: 12.5%;
}
.constructor-table .head-contstructor .room {
  width: 26.5%;
}
.constructor-table .head-contstructor .col-name {
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-right: 20px;
  padding-right: 15px;
}
.constructor-table .head-contstructor .col-name::after, .constructor-table .head-contstructor .col-name::before {
  content: "";
  width: 0;
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 0;
  border-style: solid;
  border-width: 6px 3px 0 3px;
  border-color: #243341 transparent transparent;
}
.constructor-table .head-contstructor .col-name::before {
  bottom: auto;
  top: 0px;
  transform: rotateX(180deg);
}
.constructor-table .head-contstructor .search-col form {
  display: flex;
  align-items: center;
}
.constructor-table .head-contstructor .search-col form i {
  font-size: 17px;
  margin-right: 10px;
}
.constructor-table .head-contstructor .search-col form input {
  border: none;
  background-color: #fff0;
  font-size: 12px;
  padding: 0;
  width: 100%;
  color: #130F26;
  line-height: 1.2;
}
.constructor-table .head-contstructor .search-col form ::-webkit-input-placeholder {
  color: #130F26;
  font-size: 12px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}
.constructor-table .head-contstructor .search-col form :-ms-input-placeholder {
  color: #130F26;
  font-size: 12px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}
.constructor-table .head-contstructor .search-col form ::placeholder {
  color: #130F26;
  font-size: 12px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}
.constructor-table .line-constructor .time .time-value {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15em;
  text-align: center;
  padding-bottom: 5px;
  display: block;
}
.constructor-table .line-constructor .time .select-format {
  background-color: #24D8C2;
  color: #fff;
  border-radius: 6px;
  padding: 5px;
  text-transform: uppercase;
  font-size: 8px;
  cursor: pointer;
  display: inline-block;
  line-height: 10px;
  letter-spacing: 0.01em;
  position: relative;
  font-weight: 700;
}

.constructor-table .line-constructor .time .select-format.hybrid {
  background: linear-gradient(90deg, #3C4EED 0%, #24D8C2 100%);
}
.constructor-table .line-constructor .time .select-format.offline {
  background-color: #3C4EED;
}
.constructor-table .line-constructor .theme-item, .constructor-table .line-constructor .room-item {
  border: 1px solid #DAEBEF;
  background: #F4F6F8;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 8px 10px;
  color: #243341;
  font-size: 12px;
  padding-right: 20px;
  font-weight: 700;
  height: 100%;
  cursor: pointer;
  line-height: 14px;
  transition: 0.2s;
  display: block;
  position: relative;
}
.constructor-table .line-constructor .theme-item::after, .constructor-table .line-constructor .room-item::after {
  content: "\e903";
  font-family: "naviconf";
  font-size: 10px;
  color: #243341;
  position: absolute;
  right: 15px;
  font-weight: 100;
  top: 20px;
}
.constructor-table .line-constructor .room-item {
  padding: 12px;
  padding-right: 25px;
}
.constructor-table .line-constructor .room-item::after {
  top: 10px;
  right: 15px;
}
.constructor-table .line-constructor .speakers-list li {
  border: 1px solid #DAEBEF;
  background: #F4F6F8;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 8px 10px;
  color: #243341;
  font-size: 12px;
  padding-left: 20px;
  padding-right: 17px;
  position: relative;
  font-weight: 700;
  margin-bottom: 4px;
  cursor: pointer;
  line-height: 14px;
  transition: 0.2s;
}
.constructor-table .line-constructor .speakers-list li:last-child {
  margin-bottom: 0;
}
.constructor-table .line-constructor .speakers-list li .status-speaker {
  padding-top: 5px;
  display: block;
  font-weight: 600;
  position: relative;
}
.constructor-table .line-constructor .speakers-list li .status-speaker::after {
  content: "\e903";
  font-family: "naviconf";
  font-size: 7px;
  color: #243341;
  position: absolute;
  right: 0px;
  font-weight: 100;
}
.constructor-table .line-constructor .speakers-list li .status-speaker b {
  font-weight: 700;
  padding-left: 10px;
}
.constructor-table .line-constructor .speakers-list li .status-speaker .yellow {
  color: #FF8F0C;
}
.constructor-table .line-constructor .speakers-list li .status-speaker .green {
  color: #2FDD99;
}
.constructor-table .line-constructor .speakers-list li .status-lock {
  width: 20px;
  background-color: #fff;
  height: 21px;
  display: inline-block;
  font-size: 8px;
  color: #6C7A7D;
  border-top: 1px solid #DAEBEF;
  border-bottom: 1px solid #DAEBEF;
  position: absolute;
  border-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  margin: auto;
  left: -16px;
}
.constructor-table .line-constructor .speakers-list li .status-lock i {
  position: relative;
  z-index: 1;
}
.constructor-table .line-constructor .speakers-list li .status-lock::before, .constructor-table .line-constructor .speakers-list li .status-lock::after {
  content: "";
  width: 13px;
  height: 13px;
  position: absolute;
  border-left: 1px solid #DAEBEF;
  border-bottom: 1px solid #DAEBEF;
  border-radius: 2px;
  transform: rotate(45deg) skew(10deg, 10deg);
  background-color: #fff;
  top: 3px;
  left: -6px;
  z-index: 0;
}
.constructor-table .line-constructor .speakers-list li .status-lock::after {
  left: auto;
  right: -6px;
  transform: rotate(225deg) skew(10deg, 10deg);
}
.constructor-table .line-constructor .speakers-list li .status-lock.status-green {
  border-color: #2FDD99;
  background-color: #2fdd99;
  color: #fff;
}
.constructor-table .line-constructor .speakers-list li .status-lock.status-green::after, .constructor-table .line-constructor .speakers-list li .status-lock.status-green::before {
  border-color: #2fdd99;
  background-color: #2fdd99;
}
.constructor-table .line-constructor .duration-item {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
}
.constructor-table .line-constructor .duration-item label {
  width: 50px;
  position: relative;
}
.constructor-table .line-constructor .duration-item label span {
  position: absolute;
  bottom: -15px;
  display: block;
  text-align: center;
  width: 100%;
  left: 0;
  color: #6C7A7D;
  font-size: 12px;
}
.constructor-table .line-constructor .duration-item input {
  border: none;
  width: 100%;
  padding: 0;
  font-size: 14px;
  text-align: center;
  font-weight: 300;
}
.constructor-table .line-constructor .duration-item .pluse, .constructor-table .line-constructor .duration-item .minus {
  cursor: pointer;
}

.list-theme {
  max-height: 0;
  overflow: hidden;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
}

.h1 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
}

.conf-detail {
  display: flex;
  justify-content: space-between;
}

.conf-detail .conf-info .title {
  margin-bottom: 30px;
}
.conf-detail .conf-info .tags {
  margin-bottom: 25px;
}
.conf-detail .conf-info .tags li {
  display: inline-block;
  padding: 6px;
  border-radius: 6px;
  background-color: #d5dee8;
  text-transform: uppercase;
  font-size: 8px;
  line-height: 10px;
  letter-spacing: 0.1em;
  font-weight: 700;
  margin-right: 10px;
}
.conf-detail .conf-info .tags li:last-child {
  margin-right: 0;
}
.conf-detail .conf-info .meta {
  display: flex;
}
.conf-detail .conf-info .meta .date, .conf-detail .conf-info .meta .address {
  border-radius: 10px;
  border: 1px solid #DAEBEF;
  padding: 14px 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}
.conf-detail .conf-info .meta .date i, .conf-detail .conf-info .meta .address i {
  font-size: 25px;
  margin-right: 20px;
}
.conf-detail .conf-info .meta .date span, .conf-detail .conf-info .meta .address span {
  display: block;
}
.conf-detail .conf-info .meta .date {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.conf-detail .conf-info .meta .date .day {
  display: flex;
  align-items: center;
  padding-right: 30px;
  margin-right: 30px;
  position: relative;
}
.conf-detail .conf-info .meta .date .day::after {
  content: "";
  width: 1px;
  height: 34px;
  background-color: #A9BBCB;
  opacity: 0.2;
  position: absolute;
  right: 0;
}
.conf-detail .conf-info .meta .address {
  display: flex;
  align-items: center;
  padding-right: 100px;
}

.conf-links {
  margin-top: 20px;
  margin-left: 120px;
}
.conf-links .btn-icon {
  margin-bottom: 45px;
  min-width: 280px;
}
.conf-links .btn-icon:last-child {
  margin-bottom: 0;
}

.tabs-setting-conf-contents {
  padding: 45px 30px;
  background-color: #fff;
  border-radius: 0 0px 20px 20px;
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  margin-bottom: 20px;
}

.tabs-setting-conf {
  display: flex;
  align-items: flex-end;
}
.tabs-setting-conf .item {
  width: calc(25% - 20px);
  border-radius: 15px 15px 0 0;
  border: 1px solid #fff;
  background: linear-gradient(270deg, #F4F6F8 1.13%, #EFF2F4 98.87%);
  padding: 15px 20px;
  padding-right: 50px;
  position: relative;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
}
.tabs-setting-conf .item .icon-tab {
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 19px;
  background: #24D8C2;
  border-radius: 9px;
  margin-right: 15px;
  min-width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabs-setting-conf .item .icon-tab.offline {
  background: #3C4EED;
}
.tabs-setting-conf .item .icon-tab.gybrid {
  background: linear-gradient(90deg, #3C4EED 0%, #24D8C2 100%);
}
.tabs-setting-conf .item.active {
  padding-top: 25px;
  background: #fff;
}
.tabs-setting-conf .item .close-tab {
  margin-left: 20px;
  font-size: 13px;
  position: absolute;
  right: 20px;
  color: #A9BBCB;
  cursor: pointer;
}
.tabs-setting-conf .item .close-tab:hover {
  color: #243341;
}
.tabs-setting-conf .item-options {
  width: 80px;
  padding: 22.5px;
  font-size: 20px;
  color: #A9BBCB;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-icon {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  color: #243341;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.05em;
}
.btn-icon i {
  font-size: 60px;
  margin-right: 25px;
}
.btn-icon:hover {
  color: #36B2F1;
}

.block {
  background: #fff;
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  border-radius: 15px;
}
.block .header-block {
  padding: 30px;
  border-bottom: 1px solid #DBE6EF;
}
.block .header-block .title {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
}
.block .content-block {
  padding: 30px;
}

.settings-block {
  display: flex;
  justify-content: space-between;
}
.settings-block .item {
  width: calc(33% - 40px);
}
.settings-block .item .title {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 20px;
}

.sub-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}

textarea {
  width: 100%;
  height: 140px;
  border: 1px solid #A9BBCB;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 15px;
  font-family: "Montserrat", sans-serif;
  resize: none;
  color: #6C7A7D;
}

::-webkit-input-placeholder {
  color: #6C7A7D;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #6C7A7D;
  opacity: 1;
}

::placeholder {
  color: #6C7A7D;
  opacity: 1;
}

.file-label {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  border: 1px dashed #DBE6EF;
  background: #F9FAFB;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  flex-flow: column;
  margin-bottom: 20px;
}
.file-label .edit-btns {
  position: absolute;
  right: 8px;
  top: 16px;
  display: flex;
}
.file-label .edit-btns .edit, .file-label .edit-btns .delete {
  background: #FFFFFF;
  border: 1px solid #DAEBEF;
  box-sizing: border-box;
  border-radius: 6px;
  width: 32px;
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
}
.file-label .edit-btns .edit:hover, .file-label .edit-btns .delete:hover {
  background: #DAEBEF;
}
.file-label .edit-btns .edit {
  margin-right: 10px;
}
.file-label .edit-btns .delete {
  color: #FF5C5C;
}
.file-label label {
  color: #36B2F1;
  position: relative;
  cursor: pointer;
  line-height: 17px;
  display: block;
  text-align: center;
  margin-bottom: 10px;
}
.file-label label i {
  display: block;
  font-size: 70px;
  margin-bottom: 20px;
}
.file-label label span {
  text-decoration: underline;
}
.file-label .desc-file {
  font-size: 12px;
  color: #6C7A7D;
  display: block;
  text-align: center;
}
.file-label input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}

.registration-row .title {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 20px;
}

.block .registration-row {
  padding: 30px 0;
}

.registration-row {
  display: flex;
  width: 100%;
}
.registration-row .width-1-3 {
  width: calc(33% - 60px);
  margin: 0 30px;
}
.registration-row .width-2-3 {
  width: calc(67% - 60px);
  margin: 0 30px;
}

.checkbox-input {
  position: relative;
  cursor: pointer;
}
.checkbox-input span {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 400;
}
.checkbox-input span::before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: 1px solid #A9BBCB;
  background-color: #fff;
  transition: 0.2s;
  position: relative;
  margin-right: 10px;
}
.checkbox-input span::after {
  content: "\e90a";
  font-family: "naviconf";
  font-size: 10px;
  color: #fff;
  opacity: 0;
  transition: 0.2s;
  position: absolute;
  left: 4px;
  font-weight: 400;
}
.checkbox-input input {
  position: absolute;
  left: 0;
  visibility: hidden;
}
.checkbox-input input:checked + span::before {
  background-color: #33AFEE;
  border-color: #33AFEE;
}
.checkbox-input input:checked + span::after {
  opacity: 1;
}

.row-sb {
  display: flex;
  justify-content: space-between;
}

.lines-registration .item {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F4F6F8;
  border: 1px solid #DAEBEF;
  border-radius: 10px;
  position: relative;
  margin-bottom: 5px;
}
.lines-registration .item:last-child {
  margin-bottom: 0;
}
.lines-registration .item::before {
  content: "";
  width: 10px;
  cursor: pointer;
  min-width: 10px;
  margin-right: 10px;
  height: 18px;

}
.lines-registration .delete {
  color: #FF5C5C;
  font-size: 30px;
  cursor: pointer;
}
.lines-registration .checkbox-input {
  min-width: 270px;
  margin-left: 10px;
}
.lines-registration input {
  width: 100%;
  margin: 0 10px;
}

input {
  padding: 15px;
  color: #243341;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #A9BBCB;
  font-family: "Montserrat", sans-serif;
}

.registration-row .width-1-3 {
  display: flex;
  flex-flow: column;
}
.registration-row .width-1-3 textarea {
  height: 100%;
}

.link-addline {
  border: none;
  display: flex;
  padding: 0;
  margin-top: 20px;
  width: 150px;
  margin-right: 0;
  margin-left: auto;
  font-family: "Montserrat", sans-serif;
  align-items: center;
  background-color: #fff0;
}
.link-addline i {
  color: #36B2F1;
  font-size: 30px;
  margin-right: 10px;
}
.link-addline span {
  text-decoration: underline;
  color: #36B2F1;
  font-weight: 400;
}


.btn-border {
  padding: 15px 25px;
  border: 1px solid #243341;
  color: #243341;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  text-transform: uppercase;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
}
.btn-border i {
  font-size: 20px;
  margin-right: 15px;
}
.btn-border:hover {
  color: #fff;
  background: #243341;
}

.btn-member {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.btn-member a {
  margin-right: 15px;
}
.btn-member .btn-gradient {
  padding: 20px 65px;
}

.btn-gradient {
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  background: linear-gradient(270deg, #1994D2 -2.55%, #36B2F1 104.08%);
  border-radius: 10px;
  text-align: center;
  padding: 20px 30px;
}

.block-default {
  background-color: #fff;
  margin-top: 20px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
}
.block-default .title {
  font-size: 22px;
  line-height: 27px;
  font-weight: 600;
  margin-bottom: 20px;
}
.block-default p {
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 20px;
}
.block-default .title-small {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
}

.partners ul {
  display: flex;
}
.partners ul li {
  margin-right: 65px;
}
.partners ul li:last-child {
  margin-right: 0;
}

.navigation-desc {
  margin-bottom: 30px;
}
.navigation-desc img {
  border-radius: 10px;
  width: 100%;
}
.navigation-desc .row {
  margin-bottom: 50px;
  align-items: center;
}
.navigation-desc .row:last-child {
  margin-bottom: 0;
}
.navigation-desc .title {
  margin-bottom: 50px;
}

.tab-switcher {
  border: 1px solid #1994D2;
  border-radius: 10px;
  margin-bottom: 40px;
  display: inline-block;
}
.tab-switcher ul {
  display: flex;
  position: relative;
}
.tab-switcher ul li {
  padding: 16px 27px;
  border-radius: 9px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  background-color: #fff;
}
.tab-switcher ul li.active {
  background: linear-gradient(270deg, #1994D2 -2.55%, #36B2F1 104.08%);
  color: #fff;
}

.list-schedule .item {
  border-radius: 10px;
  border: 1px solid #DAEBEF;
  background-color: #F4F6F8;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.list-schedule .item:last-child {
  margin-bottom: 0;
}
.list-schedule .item .time {
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  letter-spacing: 0.15em;
  padding-right: 20px;
  margin-right: 20px;
  position: relative;
}
.list-schedule .item .time::after {
  content: "";
  width: 1px;
  height: calc(100% + 20px);
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #DAEBEF;
  position: absolute;
  right: 0;
}
.list-schedule .item.inactive {
  color: #6C7A7D;
}
.list-schedule .item .theme {
  width: 100%;
}
.list-schedule .item .title-theme {
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
  padding-bottom: 5px;
}
.list-schedule .item .desc-theme {
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
}
.list-schedule .item .favorite {
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.list-schedule .item .favorite::before {
  font-size: 30px;
  font-family: "naviconf";
}
.list-schedule .item .favorite path {
  fill: #fff0;
}
.list-schedule .item .favorite.active path {
  fill: #FAFF00;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

.option-icons svg {
  font-size: 15px;
}

.option-icons {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
}

.calendar__body-block {
  position: relative;
}

.option-icons > div:first-child {
  margin-right: 5px;
}

.constructor-table .line-constructor .theme-item::after,.constructor-table .line-constructor .room-item::after, .calendar-block__col::before, .constructor-table .line-constructor .speakers-list li .status-speaker::after {
  display: none;
}

.header__form-search i {
  font-size: 22px;
}

.header__form-input {
  padding: 0;
  border: none;
  width: 100%;
  display: block;
  background-color: #fff;
  font-family: "Montserrat", sans-serif;
  color: #130F26;
  font-size: 14px;
}

.header__blocks {
  background-color: #fff;
  box-shadow: 0px 3px 35px rgba(36, 51, 65, 0.03);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  position: relative;
  height: auto;
  margin-top: 30px;
  margin-right: 30px;
}

.header__form {
  padding-left: 0;
}

.speakers-list li {
  display: flex;
}

.speakers-list .calendar-block__select {
  width: 100%;
}

.status-speaker {
  max-width: 172px;
  box-sizing: border-box;
}

.constructor-table .line-constructor .speakers-list li {
  padding-left: 5px;
  padding-right: 5px;
}

.constructor-table .line-constructor .speakers-list li .status-speaker {
  padding-right: 0;
}

.speaker-choose {
  min-width: calc(100% - 160px);
}

.addSpeaker {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 17px;
  cursor: pointer;
  color: #33afee;
}
@charset "UTF-8";
/* CSS Document */

@media screen and (max-width: 1920px) {
    .btn-border {
        font-size: 10px;
    }
    .btn-border i {
        font-size: 14px;
        margin-right: 10px;
    }
    .conf-detail .conf-info .meta .date, .conf-detail .conf-info .meta .address {
        font-size: 12px;
        padding: 10px 15px;
    }
    .conf-detail .conf-info .meta .date i, .conf-detail .conf-info .meta .address i {
        font-size: 20px;
        margin-right: 10px;
    }
}

@media screen and (max-width: 1800px) {
    body {
        font-size: 11px;
    }
    .btn-member a {
        font-size: 10px;
    }
    .btn-member .btn-gradient {
        padding: 15px 35px;
    }
    .btn-member a {
        font-size: 10px;
        padding: 9px 15px;
        border-radius: 5px;
    }
    .btn-border i {
        font-size: 12px;
        margin-right: 5px;
    }
    .title-page {
        font-size: 22px;
    }
    .filter-date .title-date {
        padding: 0 25px;
        font-size: 18px;
    }
    .filter-date .filter-arrows {
        font-size: 13px;
    }
    .constructor-table .head-contstructor {
        font-size: 11px;
    }
    .constructor-table .line-constructor .theme-item, .constructor-table .line-constructor .room-item, .constructor-table .line-constructor .name-parent {
        font-size: 11px;
    }
    .constructor-table .line-constructor .duration-item label span {
        bottom: -10px;
        font-size: 10px;
    }
    .tabs-setting-conf .item {
        font-size: 11px;
    }
    input {
        padding: 10px;
        font-size: 10px;
        border-radius: 5px;
    }
    .h1 {
        font-size: 23px;
        line-height: 30px;
    }
    article {
        padding-bottom: 30px;
    }
    .tabs-setting-conf .item-options {
        font-size: 19px;
    }
    .constructor-table .line-constructor .speakers-list li .status-speaker b {
        display: block;
        padding: 0;
        font-size: 9px;
        line-height: 1.4;
    }
    .constructor-table .line-constructor .speakers-list li .status-speaker::after {
        bottom: 0;
    }
    .constructor-table .head-contstructor .search-col form input {
        font-size: 9px;
    }
    .constructor-table .body-contstructor {
        max-height: calc(100vh - 120px);
    }
    .breadcrumbs {
        margin-bottom: 25px;
    }
    article {
        padding-bottom: 0;
    }
}
