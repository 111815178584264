@charset "UTF-8";
/* CSS Document */

@media screen and (max-width: 1920px) {
    .btn-border {
        font-size: 10px;
    }
    .btn-border i {
        font-size: 14px;
        margin-right: 10px;
    }
    .conf-detail .conf-info .meta .date, .conf-detail .conf-info .meta .address {
        font-size: 12px;
        padding: 10px 15px;
    }
    .conf-detail .conf-info .meta .date i, .conf-detail .conf-info .meta .address i {
        font-size: 20px;
        margin-right: 10px;
    }
}

@media screen and (max-width: 1800px) {
    body {
        font-size: 11px;
    }
    .btn-member a {
        font-size: 10px;
    }
    .btn-member .btn-gradient {
        padding: 15px 35px;
    }
    .btn-member a {
        font-size: 10px;
        padding: 9px 15px;
        border-radius: 5px;
    }
    .btn-border i {
        font-size: 12px;
        margin-right: 5px;
    }
    .title-page {
        font-size: 22px;
    }
    .filter-date .title-date {
        padding: 0 25px;
        font-size: 18px;
    }
    .filter-date .filter-arrows {
        font-size: 13px;
    }
    .constructor-table .head-contstructor {
        font-size: 11px;
    }
    .constructor-table .line-constructor .theme-item, .constructor-table .line-constructor .room-item, .constructor-table .line-constructor .name-parent {
        font-size: 11px;
    }
    .constructor-table .line-constructor .duration-item label span {
        bottom: -10px;
        font-size: 10px;
    }
    .tabs-setting-conf .item {
        font-size: 11px;
    }
    input {
        padding: 10px;
        font-size: 10px;
        border-radius: 5px;
    }
    .h1 {
        font-size: 23px;
        line-height: 30px;
    }
    article {
        padding-bottom: 30px;
    }
    .tabs-setting-conf .item-options {
        font-size: 19px;
    }
    .constructor-table .line-constructor .speakers-list li .status-speaker b {
        display: block;
        padding: 0;
        font-size: 9px;
        line-height: 1.4;
    }
    .constructor-table .line-constructor .speakers-list li .status-speaker::after {
        bottom: 0;
    }
    .constructor-table .head-contstructor .search-col form input {
        font-size: 9px;
    }
    .constructor-table .body-contstructor {
        max-height: calc(100vh - 120px);
    }
    .breadcrumbs {
        margin-bottom: 25px;
    }
    article {
        padding-bottom: 0;
    }
}