html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    border: none
}

/* Хак для увеличения шрифта, чтоб перекрыть MuiGrid-container-1 */
html {
    font-size: 16px !important;
}

.h100 {
    height: 100%;
}

.flex {
    display: flex;
}

.site-menu {
    height: 100%;
    background: #f8f8f8;
}

.site-content .content {
    margin: 10px;
}

.ql-editor {
    min-height: 190px !important;
    max-height: 190px;
    overflow: hidden;
    overflow-y: scroll;
}