body {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    font-weight: normal;
    color: #0b1218;
    zoom: 0.8; 
}

h1 {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.19;
    letter-spacing: -0.8px;
    color: #0b1218;
}

button {
    cursor: pointer;
}

.js-button {
    border-radius: 16px;
    background-color: #f1f1f1;
    padding: 5px 10px;
    margin-right: 5px;
    font-size: 16px;
    border: none;
}
.js-button:first-child {
    margin-left: 10px;
}
.js-button.small {
    padding: 1px 20px;
}
.js-button:hover {
    background-color: #e0e0e1;
}
.js-button.selected {
    background-color: #444;
    color: #f1f1f1;
}

a {
    outline: none !important;
    text-decoration: none !important;
    color: unset !important;
}

input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
}

.page {
    margin: 0 30px;
}

.rowcell {
    width: 420px;
}

.row {
    width: 860px;    
    flex:1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tablestr {
		
        
		&:hover {
			background: #11B;
		} 
}

.page-table-row:hover {
    cursor: pointer;
    border-radius: 4px;
    background-color: rgba(11, 18, 24, 0.06);
}

.MuiTableCell-root.table-cell-btns {
    min-width: 206px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.header .title {
    display: flex;
    align-items: center;
}

.filter-bar {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

:focus {
    outline-style: none;
}

.search-rectangle {
    width: 500px;
    height: 46px;
    color: rgba(11, 18, 24, 0.46);
}

.select-rectangle {
    min-width: max-content;
    height: 46px;
    color: #0b1218;
}

.select-rectangle:hover {
    background-color: #e0e0e1;
}

.search-rectangle, .select-rectangle {
    border-radius: 23px;
    background-color: #f1f1f1;
    font-size: 17px;
    line-height: 1.29;
    letter-spacing: 0.73px;
}

.break-word {
    word-break: break-word;
}

.MuiDialog-paperWidthMd {
    width: 956px;
}

.MuiList-root.MuiMenu-list.MuiList-padding {
    padding: 0;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text {
    min-width: unset;
}

.MuiTableCell-root.MuiTableCell-head {
    font-size: 16px;
    line-height: 1.33;
    letter-spacing: 0.65px;
    color: rgba(11, 18, 24, 0.46);
    font-weight: normal;
}

.MuiTableCell-root.MuiTableCell-body .a,
.MuiTableCell-root.MuiTableCell-body a {
    cursor: pointer;
    text-decoration: underline solid #a2a2a2 !important;
}

.MuiTableCell-root.MuiTableCell-body .a:hover,
.MuiTableCell-root.MuiTableCell-body a:hover {
    text-decoration-color: #000 !important;
}

.table-cell-img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
}

.required-icon {
    width: 24px;
    height: 24px;
}

.menu-drag-icon, .search-icon {
    color: rgba(11, 18, 24, 0.48);
}

.search-rectangle .MuiSvgIcon-root {
    color: rgba(11, 18, 24, 0.46);
    margin-left: 10px;
}

.select-rectangle .MuiSvgIcon-root {
    color: #0b1218;
    margin-right: 10px;
}

.select-rectangle .MuiNativeSelect-root.MuiNativeSelect-select.MuiInputBase-input.MuiInput-input.MuiInputBase-inputSelect {
    margin: 0 20px;
}

.select-rectangle .MuiNativeSelect-select:focus {
    background-color: rgba(0, 0, 0, 0) !important;
}

.status-indicator-green,
.status-indicator-gray,
.status-indicator-blue,
.status-indicator-pink {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
}
.status-indicator-green {
    background-color: #00d358;
}

.status-indicator-gray {
    background-color: #d1dce7;
}

.status-indicator-blue {
    background-color: #40b6f9
}

.status-indicator-pink {
    background-color: #ff9591;
}

.MuiPaper-root.MuiDialog-paper {
    padding: 36px;
}

.MuiDialogContent-root {
    padding: 8px 30px;
}

.ql-editor li {
    margin-top: .4em !important;
}

.ql-editor ol,
.ql-editor ul,
.ql-editor p {
    margin-bottom: .5em !important;
}

#emoji-palette {
    max-width: 500px !important;
}

.MuiGrid-item {
    padding: 0 0 20px 0;
}

.task-mini {
    display: inline-block;
    margin-right: 1em;
    width: 50px;
    height: 50px;
    resize: inherit;
    border-radius: 50%;
    background: #f1f1f1;
}

.task-mini-img-invert {
    margin: 9px;
    width: 32px;
    filter: invert(100%);
    resize: inherit;
}

.task-mini-img {
    margin: 9px;
    width: 32px;
    resize: inherit;
}

.menu-logo {
    cursor: pointer;
    margin: 30px 0 10px 16px;
    display: flex;
    min-height: 28px;
}

.menu-logo .icon {
    min-width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-logo .title {
    font-weight: bold;
    font-size: 1.2em;
}

.menu-logo.closed .icon {
    width: 100%;
    margin-left: -6px;
}

.menu-logo.closed .title {
    display: none;
}

.dialog-actions {
    justify-content: flex-start;
    margin-top: 36px;
}

.html-content h1 {
    font-size: 23px;
    padding: 0;
    margin: .3em 0;
}

.html-content h2 {
    font-size: 18px;
    padding: 0;
    margin: .2em 0;
}

.html-content ul {
    padding: 0;
    margin: .4em 10px;
}

.html-content p {
    padding: 0;
    margin: .4em 0;
}

.hint {
    font-size: 15px;
    font-weight: normal;
    color: #a2a2a2;
}

.MuiPopover-paper {
    max-height: calc(100vh - 80px) !important;
}

.MuiTab-root.tab-item {
    font-size: 28px;
    font-weight: bold;
    line-height: 1.21;
    letter-spacing: -0.48px;
    color: rgba(11, 18, 24, 0.24);
    text-transform: unset;
}

.Mui-selected.tab-item {
    color: #0b1218;
}

.MuiTabs-root.tab-list {
    margin-top: 20px;
}

.MuiTabs-indicator.tab-indicator {
    background-color: unset;
}

.MuiToggleButton-root {
    border: none;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.21;
    letter-spacing: -0.48px;
    color: rgba(11, 18, 24, 0.24);
    text-transform: none;
}

.MuiToggleButton-root:hover {
    background-color: unset;
}

.MuiToggleButton-root.Mui-selected {
    color: #0b1218;
    background-color: unset;
}

.MuiToggleButton-root.Mui-selected:hover {
    background-color: unset;
}


.calendar__body-row:first-of-type {
	width: 160px;
	flex: 0 0 auto;
	padding: 0 30px;
}

.calendar__body-row:nth-of-type(2) {
	max-width: 215px;
	flex: 1;
	min-width: 173px;
	padding: 0 23px;
}

.calendar__body-row:nth-of-type(3) {
	max-width: 372px;
	flex: 1;
	min-width: 260px;
	padding: 0 15px 0 0;
}

.calendar__body-row:nth-of-type(4) {
	max-width: 440px;
	min-width: 440px;
	padding-left: 33px;
}

.calendar__body-row:nth-of-type(5) {
	max-width: 286px;
	min-width: 286px;
	padding-left: 30px;
}

.calendar__body-row:nth-of-type(6) {
	max-width: 74px;
	min-width: 74px;
	flex: 0 0 auto;
}
